import React from "react";

export default function Paralax3Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_ii_1172_41450)">
        <path
          d="M6.50546 61.6039C0.494009 60.0865 -1.62055 52.6306 2.69925 48.1832L46.2503 3.34587C50.5701 -1.10151 58.0844 0.795188 59.7761 6.75994L76.8308 66.895C78.5225 72.8597 73.1227 78.4189 67.1113 76.9016L6.50546 61.6039Z"
          fill="#282828"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_1172_41450"
          x="-9.58289"
          y="-5.10107"
          width="102.728"
          height="86.2556"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="16" dy="-6" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1172_41450"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-11" dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_1172_41450"
            result="effect2_innerShadow_1172_41450"
          />
        </filter>
      </defs>
    </svg>
  );
}
