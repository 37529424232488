import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import {
  AboutUs,
  Blog,
  Contacts,
  Credit,
  Delivery,
  Home,
  Job,
  ProductPage,
} from "./screens";
import Loader from "./components/Loader";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MobileFooter from "./components/MobileFooter";
import LoginModal from "./components/LoginModal";
import AddToCartAnimation from "./components/AddToCartAnimation";
import AddToFavoriteAnimation from "./components/AddToFavoriteAnimation";
import AddToCompareAnimation from "./components/AddToCompareAnimation";

const Layout = () => (
  <>
    <AddToCartAnimation />
    <AddToFavoriteAnimation />
    <AddToCompareAnimation />
    <LoginModal />
    <Header />
    <Outlet />
    <Footer />
    {window.innerWidth <= 992 && <MobileFooter />}
  </>
);
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/contacts",
        element: <Contacts />,
      },
      {
        path: "/blog",
        element: <Blog />,
      },
      {
        path: "/job",
        element: <Job />,
      },
      {
        path: "/about-us",
        element: <AboutUs />,
      },
      {
        path: "/delivery",
        element: <Delivery />,
      },
      {
        path: "/credit",
        element: <Credit />,
      },
      {
        path: "/product/:productId",
        element: <ProductPage />,
      },
    ],
  },
]);

function App() {
  return (
    <Provider store={store}>
      <Loader>
        <RouterProvider router={router}>
          <Outlet />
        </RouterProvider>
      </Loader>
    </Provider>
  );
}

export default App;
