import React from "react";

export default function WhatsappSvg(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.3839 3.53439C14.7061 1.81564 12.4144 0.833496 9.95907 0.833496C4.96651 0.833496 0.874251 4.92576 0.874251 9.91832C0.874251 11.5552 1.3244 13.1103 2.10193 14.4607L0.833328 19.1668L5.62127 17.9392C6.97172 18.6348 8.44494 19.0441 9.95907 19.0441C14.9926 19.0441 19.1667 14.9518 19.1667 9.95924C19.1667 7.50388 18.1027 5.25314 16.3839 3.53439ZM9.95907 17.489C8.60863 17.489 7.2991 17.1207 6.11235 16.425L5.86681 16.2613L3.00223 17.0389L3.77976 14.2561L3.57514 13.9697C2.83854 12.742 2.42931 11.3506 2.42931 9.91832C2.42931 5.78513 5.82589 2.38856 10 2.38856C12.0052 2.38856 13.8876 3.16609 15.3199 4.59838C16.7522 6.03067 17.6116 7.91311 17.6116 9.95924C17.6116 14.0924 14.1332 17.489 9.95907 17.489ZM14.1332 11.8417C13.8876 11.7189 12.7827 11.1869 12.5781 11.1051C12.3735 11.0232 12.2098 10.9823 12.0461 11.2278C11.9234 11.4325 11.4732 11.9644 11.3504 12.1281C11.1868 12.2509 11.064 12.2918 10.8594 12.1691C9.50892 11.5143 8.64955 10.9823 7.74925 9.46817C7.50372 9.05894 7.99479 9.09986 8.40401 8.24049C8.48586 8.0768 8.44494 7.95403 8.40401 7.83126C8.36309 7.7085 7.87202 6.60359 7.70833 6.15344C7.50372 5.70329 7.34002 5.74421 7.17633 5.74421C7.05357 5.74421 6.88988 5.74421 6.76711 5.74421C6.60342 5.74421 6.35788 5.78513 6.15327 6.03067C5.94866 6.2762 5.37574 6.8082 5.37574 7.91311C5.37574 9.05894 6.15327 10.1229 6.27604 10.2866C6.3988 10.4094 7.87202 12.7011 10.1637 13.6832C11.596 14.338 12.1689 14.3789 12.9055 14.2561C13.3147 14.2152 14.215 13.7241 14.4196 13.1921C14.6243 12.6601 14.6243 12.21 14.5424 12.1281C14.5015 12.0054 14.3378 11.9644 14.1332 11.8417Z"
        fill="#323232"
      ></path>
    </svg>
  );
}
