import React, { useState } from "react";
import styles from "./style.module.css";
import VisaSvg from "./assets/VisaSvg";
import MasterSvg from "./assets/MasterSvg";
import ArcaSvg from "./assets/ArcaSvg";
import TelCellSvg from "./assets/TelCellSvg";
import IdramSvg from "./assets/IdramSvg";
import ExpressSvg from "./assets/ExpressSvg";
import MirSvg from "./assets/MirSvg";
import EasySvg from "./assets/EasySvg";
import FacebookSvg from "../../assets/SVG/FacebookSvg";
import InstagramSvg from "../../assets/SVG/InstagramSvg";
import WhatsappSvg from "../../assets/SVG/WhatsappSvg";
import ViberSvg from "../../assets/SVG/ViberSvg";
import ButtonArrowSvg from "../../assets/SVG/ButtonArrowSvg";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Footer() {
  const [showInfoMenu, setShowInfoMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);
  const [showContactMenu, setShowContactMenu] = useState(false);
  const [showPaymentMenu, setShowPaymentMenu] = useState(false);

  const { currentLanguage } = useSelector(({ main }) => main);

  return (
    <footer className={styles.footer}>
      <div className="wrapper" style={{ margin: "0 auto" }}>
        <img
          className={styles.logo}
          alt="VLV"
          src={require("../../assets/logo.png")}
        />
      </div>
      <div className={styles.line} />
      <div className={`wrapper ${styles.menuContainer}`}>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowInfoMenu(!showInfoMenu);
            }}
          >
            <h5>Տեղեկատվություն</h5>
            <ButtonArrowSvg
              style={showInfoMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showInfoMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <NavLink>
              <span>Ակցիաներ</span>
            </NavLink>
            <NavLink to="/delivery">
              <span>Առաքում</span>
            </NavLink>
            <NavLink>
              <span>Վճարում</span>
            </NavLink>
            <NavLink to="credit">
              <span>Ապառիկ</span>
            </NavLink>
            <NavLink>
              <span>Պատվեր</span>
            </NavLink>
          </div>
        </div>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowAboutMenu(!showAboutMenu);
            }}
          >
            <h5>«ՎիԷլՎի սենթր»-ի մասին</h5>
            <ButtonArrowSvg
              style={showAboutMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showAboutMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <NavLink to="about-us">
              <span>Մեր մասին</span>
            </NavLink>
            <NavLink>
              <span>ՀՏՀ</span>
            </NavLink>
            <NavLink to="job">
              <span>Աշխատատեղեր</span>
            </NavLink>
            <NavLink to="contact">
              <span>Մեր խանութները</span>
            </NavLink>
            <NavLink>
              <span>Սպասարկում</span>
            </NavLink>
            <NavLink>
              <span>Գաղտնիության քաղաքականություն</span>
            </NavLink>
          </div>
        </div>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowContactMenu(!showContactMenu);
            }}
          >
            <h5>Կապ</h5>
            <ButtonArrowSvg
              style={showContactMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showContactMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <span>Արշակունյաց 69/5</span>
            <span>info@vlv.am</span>
            <span>010-34-99-44</span>
          </div>
        </div>
        <div className={styles.menu}>
          <div
            className={styles.titleBlock}
            onClick={() => {
              window.innerWidth < 768 && setShowPaymentMenu(!showPaymentMenu);
            }}
          >
            <h5>Վճարման տարբերակներ</h5>
            <ButtonArrowSvg
              style={showPaymentMenu ? { transform: "rotate(180deg)" } : {}}
            />
          </div>
          <div
            className={styles.menuList}
            style={
              !showPaymentMenu && window.innerWidth < 768
                ? { height: 0, overflow: "hidden" }
                : {}
            }
          >
            <p className={styles.menuText}>
              Ընդունում ենք վճարումներ կանխիկ և անկանխիկ{" "}
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                Վճարման տարբերակներ
              </a>
            </p>
            <div className={styles.paymentTypes}>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <VisaSvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <MasterSvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <ArcaSvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <TelCellSvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <IdramSvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <EasySvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <ExpressSvg />
              </a>
              <a href={"https://vlv.am/pay?lang=" + currentLanguage}>
                <MirSvg />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.menu}>
          <div className={styles.menuText}>
            Բաժանորդագրվեք ակցիաներին և նորություններին
          </div>
          <div className={styles.input}>
            <input placeholder="Էլ․ հասցե" type="email" />
            <div className={styles.inputBtn}>
              <span>Բաժանորդագրվել</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div className="wrapper" style={{ margin: "0 auto" }}>
        <h5>Մենք սոց․ ցանցերում</h5>
        <div className={styles.socIconsContainer}>
          <a
            href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d"
            aria-label="facebook"
          >
            <FacebookSvg />
          </a>
          <a
            href="https://www.instagram.com/vlv_electronics/"
            aria-label="instagram"
          >
            <InstagramSvg />
          </a>
          <a herf="https://wa.me/37491296655" aria-label="whatsapp">
            <WhatsappSvg />
          </a>
          <a href="viber://chat?number=+37491296655/" aria-label="viber">
            <ViberSvg />
          </a>
        </div>
      </div>
      <div className={styles.line} />
      <div className={`wrapper ${styles.copyrightContainer}`}>
        <p className={styles.copyright}>
          {
            "© Հեղինակային իրավունքները պաշտպանված են <<ՎիէլՎի Սենթր>> ՍՊԸ, 2014-2023"
          }
        </p>
      </div>
    </footer>
  );
}
