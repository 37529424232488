import React from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";

export default function Blog() {
  const { blogs, currentLanguage } = useSelector(({ main }) => main);
  return (
    <div className="wrapper">
      <div className={styles.titleBlock}>
        <h3 className="title">ԲԼՈԳ</h3>
        <a href="https://vlv.am/blog">
          <span className={styles.moreBtn}>ՏԵՍՆԵԼ ԲՈԼՈՐԸ</span>
        </a>
      </div>

      <div className={styles.main}>
        {!blogs.length
          ? Array.from({ length: 4 }).map((_, index) => (
              <div className={styles.blogItem} key={index}>
                <LoadContent containerClassName={styles.loadContentImg} />
                <LoadContent containerClassName={styles.loadContentTitle} />
                <LoadContent containerClassName={styles.loadContentDate} />
              </div>
            ))
          : blogs.map((item, index) => {
              let date = new Date("2023-07-15T09:32:12.000000Z")
                .toDateString()
                .split(" ");
              return (
                <a
                  href={`https://vlv.am/blog/${item.title_en
                    .split(" ")
                    .join("-")}?lang=${currentLanguage}`}
                  className={styles.blogItem}
                  key={index}
                >
                  <img
                    className={styles.blogItemImg}
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      item["image_url_" + currentLanguage]
                    }
                    alt={item.title_en}
                  />
                  <h4 className={styles.blogItemTitle}>
                    {item["title_" + currentLanguage].substring(0, 45)}...
                  </h4>
                  <span
                    className={styles.blogItemDate}
                  >{`${date?.[2]} ${date?.[1]} ${date?.[3]}`}</span>
                </a>
              );
            })}
      </div>
    </div>
  );
}
