import React from "react";

export default function MailSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="25"
      viewBox="0 0 33 25"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0872 17.6035C17.6497 18.666 15.7122 18.666 14.2747 17.6035L0.712158 7.35352V20.3535C0.712158 22.6035 2.46216 24.3535 4.71216 24.3535H28.7122C30.8997 24.3535 32.7122 22.6035 32.7122 20.3535V7.35352L19.0872 17.6035Z"
        fill="#282828"
        fill-opacity="0.4"
      />
      <path
        d="M29.7122 0.353516C31.3372 0.353516 32.7122 1.72852 32.7122 3.35352C32.7122 4.35352 32.2122 5.22852 31.4622 5.79102L17.8997 15.9785C17.1497 16.541 16.2122 16.541 15.4622 15.9785L1.89966 5.79102C1.14966 5.22852 0.712158 4.35352 0.712158 3.35352C0.712158 1.72852 2.02466 0.353516 3.71216 0.353516H29.7122Z"
        fill="#E31335"
      />
    </svg>
  );
}
