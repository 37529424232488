import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import PercentSvg from "./assets/PercentSvg";
import Timer from "./components/Timer";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Product from "./components/Product/Product";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

export default function SpecialOffer() {
  const specialOffer = useSelector(({ main }) => main.specialOffer);
  const [products, setProducts] = useState([]);
  const [loadVideo, setLoadVideo] = useState(false);
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const containerRef = useRef();
  const videoRef = useRef();

  useEffect(() => {
    let productsArray1 = [];
    let productsArray2 = [];

    specialOffer?.products?.forEach((product, index) => {
      if (productsArray1.length >= 2) {
        productsArray2.push(productsArray1);
        productsArray1 = [product];
        if (index + 1 === specialOffer?.products.length) {
          productsArray2.push(productsArray1);
        }
      } else {
        productsArray1.push(product);
      }
    });
    setProducts(productsArray2);
  }, [specialOffer?.products]);

  const scrollListnerFunc = (e) => {
    if (
      document.body.parentNode.scrollTop >=
        containerRef.current.offsetTop - window.innerHeight - 300 &&
      !loadVideo
    ) {
      setLoadVideo(true);
    }
    if (
      document.body.parentNode.scrollTop + window.innerHeight >=
        containerRef.current.offsetTop &&
      document.body.parentNode.scrollTop <=
        containerRef.current.offsetTop + window.innerHeight
    ) {
      videoRef.current?.play();
    } else {
      videoRef.current?.pause();
    }
  };
  useEffect(() => {
    document.addEventListener("scroll", scrollListnerFunc);
    return () => {
      document.removeEventListener("scroll", scrollListnerFunc);
    };
  }, [containerRef]);
  const swiperRef = useRef();

  const slidesPerView =
    window.innerWidth > 1280
      ? 2
      : window.innerWidth < 550
      ? 1.2
      : window.innerWidth < 690
      ? 1.5
      : window.innerWidth < 880
      ? 2
      : 3;

  return (
    <div className="wrapper" ref={containerRef}>
      <div className={styles.main}>
        {!specialOffer?.flashDeals?.video_link || !loadVideo ? (
          <LoadContent containerClassName={styles.loadContentVideo} />
        ) : (
          <div className={styles.video}>
            <video
              ref={videoRef}
              src={
                process.env.REACT_APP_STORAGE_URL +
                specialOffer?.flashDeals?.video_link
              }
              preload={"auto"}
              data-wf-ignore
              loop={true}
              muted
              autoPlay={true}
              type="video/mp4"
              onError={(error) => {
                console.log("video error", error);
              }}
            />
          </div>
        )}
        <div className={styles.rightBlock}>
          {!specialOffer?.flashDeals?.start_date ? (
            <LoadContent containerClassName={styles.loadContentTimer} />
          ) : (
            <div className={styles.timeBlock}>
              <div className={styles.imgTitle}>
                <div className={styles.percent}>
                  <PercentSvg />
                </div>
                <div className={styles.titles}>
                  <span className={styles.title}>Հատուկ առաջարկ</span>
                  <span className={styles.startTime}>
                    Ակցիան հասանելի է{" "}
                    {new Date(specialOffer?.flashDeals?.start_date)
                      .toLocaleDateString()
                      .split(".")
                      .join("/")}
                  </span>
                </div>
              </div>
              <Timer endDate={specialOffer?.flashDeals?.end_date} />
            </div>
          )}
          <div
            className={styles.swiperContainer}
            onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
            onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
          >
            {window.innerWidth > 768 && (
              <>
                <div
                  className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                  style={{
                    opacity: slidePosition.isBeginning ? "0.35" : "1",
                    display: showBtns ? "flex" : "none",
                  }}
                  onClick={() => swiperRef.current?.slidePrev()}
                >
                  <SliderNextArrowSvg />
                </div>
                <div
                  className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                  style={{
                    opacity: slidePosition.isEnd ? "0.35" : "1",
                    display: showBtns ? "flex" : "none",
                  }}
                  onClick={() => swiperRef.current?.slideNext()}
                >
                  <SliderNextArrowSvg />
                </div>
              </>
            )}
            <Swiper
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
              onSlidesLengthChange={(e) => {
                if (slidesPerView < e.slides.length) {
                  setSlidePosition({
                    isEnd: false,
                    isBeginning: true,
                  });
                } else {
                  setSlidePosition({
                    isEnd: true,
                    isBeginning: true,
                  });
                }
              }}
              spaceBetween={10}
              onSlideChange={(e) => {
                setSlidePosition({
                  isEnd: e.isEnd,
                  isBeginning: e.isBeginning,
                });
              }}
              slidesPerView={slidesPerView}
            >
              {!products.length ? (
                <>
                  {Array.from({ length: 2 }).map(() => (
                    <SwiperSlide>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "30px",
                        }}
                      >
                        <LoadContent
                          containerClassName={styles.loadContentProduct}
                        />
                        <LoadContent
                          containerClassName={styles.loadContentProduct}
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              ) : (
                products?.map((productsArray) => {
                  return (
                    <SwiperSlide>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          rowGap: "30px",
                        }}
                      >
                        <Product product={productsArray[0]} />
                        {productsArray[1] && (
                          <Product product={productsArray[1]} />
                        )}
                      </div>
                    </SwiperSlide>
                  );
                })
              )}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}
