import React from "react";

export default function InstagramSvg(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.0205 5.2736C7.40142 5.2736 5.31437 7.40158 5.31437 9.9797C5.31437 12.5988 7.40142 14.6858 10.0205 14.6858C12.5986 14.6858 14.7266 12.5988 14.7266 9.9797C14.7266 7.40158 12.5986 5.2736 10.0205 5.2736ZM10.0205 13.0489C8.34264 13.0489 6.95128 11.6985 6.95128 9.9797C6.95128 8.30187 8.30172 6.95143 10.0205 6.95143C11.6983 6.95143 13.0487 8.30187 13.0487 9.9797C13.0487 11.6985 11.6983 13.0489 10.0205 13.0489ZM15.9952 5.10991C15.9952 5.72375 15.5041 6.21482 14.8903 6.21482C14.2764 6.21482 13.7854 5.72375 13.7854 5.10991C13.7854 4.49607 14.2764 4.005 14.8903 4.005C15.5041 4.005 15.9952 4.49607 15.9952 5.10991ZM19.1053 6.21482C19.0234 4.74161 18.6961 3.43208 17.6321 2.36809C16.5681 1.30411 15.2586 0.976725 13.7854 0.89488C12.2712 0.813035 7.72881 0.813035 6.21467 0.89488C4.74145 0.976725 3.47285 1.30411 2.36794 2.36809C1.30395 3.43208 0.976573 4.74161 0.894727 6.21482C0.812882 7.72896 0.812882 12.2714 0.894727 13.7855C0.976573 15.2587 1.30395 16.5273 2.36794 17.6322C3.47285 18.6962 4.74145 19.0236 6.21467 19.1054C7.72881 19.1873 12.2712 19.1873 13.7854 19.1054C15.2586 19.0236 16.5681 18.6962 17.6321 17.6322C18.6961 16.5273 19.0234 15.2587 19.1053 13.7855C19.1871 12.2714 19.1871 7.72896 19.1053 6.21482ZM17.141 15.3815C16.8545 16.1999 16.1998 16.8138 15.4223 17.1412C14.1946 17.6322 11.33 17.5095 10.0205 17.5095C8.67003 17.5095 5.80544 17.6322 4.61869 17.1412C3.80023 16.8138 3.18639 16.1999 2.85901 15.3815C2.36794 14.1947 2.49071 11.3301 2.49071 9.9797C2.49071 8.67018 2.36794 5.80559 2.85901 4.57792C3.18639 3.80039 3.80023 3.18655 4.61869 2.85917C5.80544 2.36809 8.67003 2.49086 10.0205 2.49086C11.33 2.49086 14.1946 2.36809 15.4223 2.85917C16.1998 3.14562 16.8136 3.80039 17.141 4.57792C17.6321 5.80559 17.5093 8.67018 17.5093 9.9797C17.5093 11.3301 17.6321 14.1947 17.141 15.3815Z"
        fill="black"
      ></path>
    </svg>
  );
}
