import React from "react";

export default function MapMarkerSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="28"
      viewBox="0 0 21 28"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.65297 27.3535C9.65297 27.3535 20.259 16.4359 20.259 10.714C20.259 4.99206 15.7819 0.353516 10.259 0.353516C4.73619 0.353516 0.259033 4.99206 0.259033 10.714C0.259033 16.4359 9.65297 27.3535 9.65297 27.3535ZM10.259 12.5678C11.9849 12.5678 13.384 11.1287 13.384 9.35352C13.384 7.57832 11.9849 6.13923 10.259 6.13923C8.53315 6.13923 7.13403 7.57832 7.13403 9.35352C7.13403 11.1287 8.53315 12.5678 10.259 12.5678Z"
        fill="#E31335"
      />
    </svg>
  );
}
