import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import LoadContent from "../../../../components/LoadContent";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

export default function BanerSlider() {
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });
  const { bannerSliders, currentLanguage } = useSelector(({ main }) => main);

  const swiperRef = useRef();
  return (
    <div
      className={styles.container}
      onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
      onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
    >
      {!bannerSliders.length ? (
        <LoadContent containerClassName={styles.loadContent} />
      ) : (
        <>
          {window.innerWidth > 768 && (
            <div
              className="wrapper"
              style={{
                position: "absolute",
                height: "400px",
                left: 0,
                right: 0,
              }}
            >
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                style={{
                  opacity: slidePosition.isBeginning ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                style={{
                  opacity: slidePosition.isEnd ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slideNext()}
              >
                <SliderNextArrowSvg />
              </div>
            </div>
          )}

          <Swiper
            loop={false}
            slidesPerView={1}
            spaceBetween={20}
            onSlideChange={(e) => {
              setSlidePosition({
                isEnd: e.isEnd,
                isBeginning: e.isBeginning,
              });
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            modules={[Pagination, Autoplay]}
            pagination={{ clickable: true }}
            centeredSlides={true}
          >
            {bannerSliders.map((item, index) => (
              <SwiperSlide key={index}>
                <img
                  className={styles.slideImg}
                  src={
                    process.env.REACT_APP_STORAGE_URL +
                    item["image_" + currentLanguage]
                  }
                  alt="VLV baner"
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      )}
    </div>
  );
}
