import React from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";

export default function Delivery() {
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "Առաքում", value: "/delivery", active: true }]}
      />
    </div>
  );
}
