import React from "react";

export default function UserSvg() {
  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7943 13.1764C13.0973 13.1764 14.3711 12.79 15.4545 12.0661C16.5379 11.3422 17.3823 10.3133 17.881 9.10942C18.3796 7.90558 18.5101 6.58091 18.2559 5.30292C18.0017 4.02493 17.3742 2.85103 16.4528 1.92965C15.5315 1.00827 14.3576 0.380802 13.0796 0.126594C11.8016 -0.127614 10.4769 0.00285458 9.27307 0.501501C8.06923 1.00015 7.04029 1.84458 6.31637 2.928C5.59245 4.01143 5.20605 5.28519 5.20605 6.58822C5.2078 8.33499 5.90247 10.0097 7.13763 11.2449C8.37278 12.48 10.0475 13.1747 11.7943 13.1764ZM11.7943 2.19608C12.663 2.19608 13.5121 2.45367 14.2344 2.93629C14.9567 3.4189 15.5197 4.10486 15.8521 4.90742C16.1845 5.70998 16.2715 6.59309 16.102 7.44509C15.9326 8.29708 15.5142 9.07968 14.9 9.69394C14.2857 10.3082 13.5031 10.7265 12.6511 10.896C11.7991 11.0654 10.916 10.9785 10.1135 10.646C9.31091 10.3136 8.62495 9.75065 8.14234 9.02837C7.65972 8.30608 7.40213 7.4569 7.40213 6.58822C7.40213 5.42335 7.86487 4.30619 8.68856 3.48251C9.51224 2.65882 10.6294 2.19608 11.7943 2.19608Z"
        fill="#282828"
      ></path>
      <path
        d="M11.7943 16.4707C8.73753 16.4738 5.80696 17.568 3.64552 19.5133C1.48407 21.4586 0.268283 24.0961 0.264893 26.8471C0.264893 27.1529 0.399859 27.4462 0.640101 27.6624C0.880343 27.8786 1.20618 28.0001 1.54594 28.0001C1.88569 28.0001 2.21153 27.8786 2.45177 27.6624C2.69201 27.4462 2.82698 27.1529 2.82698 26.8471C2.82698 24.7067 3.77174 22.6539 5.45344 21.1404C7.13513 19.6269 9.416 18.7766 11.7943 18.7766C14.1726 18.7766 16.4534 19.6269 18.1351 21.1404C19.8168 22.6539 20.7616 24.7067 20.7616 26.8471C20.7616 27.1529 20.8965 27.4462 21.1368 27.6624C21.377 27.8786 21.7029 28.0001 22.0426 28.0001C22.3824 28.0001 22.7082 27.8786 22.9485 27.6624C23.1887 27.4462 23.3237 27.1529 23.3237 26.8471C23.3203 24.0961 22.1045 21.4586 19.943 19.5133C17.7816 17.568 14.851 16.4738 11.7943 16.4707Z"
        fill="#282828"
      ></path>
    </svg>
  );
}
