import React from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import ShopCartSvg from "../../../../assets/SVG/ShopCartSvg";
import Product from "./components/Product";
import LoadContent from "../../../../components/LoadContent";

export default function TreeInOne() {
  const { treeInOne, currentLanguage } = useSelector(({ main }) => main);

  return (
    <div className="wrapper">
      {!Object.keys(treeInOne || {}).length ? (
        <LoadContent
          containerStyle={{
            width: "100%",
            height: "auto",
            aspectRatio: "10 / 3.33",
          }}
        />
      ) : (
        <div className={styles.main}>
          <div className={styles.leftBlock}>
            <div className={styles.title}>
              <div
                dangerouslySetInnerHTML={{
                  __html: treeInOne?.threeInOne?.["title_" + currentLanguage],
                }}
              ></div>
            </div>
            <div className={styles.priceBlock}>
              <span className={styles.bigPrice}>
                {treeInOne?.threeInOne.price?.toLocaleString("en-US")} ֏
              </span>
            </div>
            <div className={styles.btnsBlock}>
              <div className={styles.btn}>
                <ShopCartSvg />
                <span>Գնել հիմա</span>
              </div>
              <div className={styles.btn}>
                <span>Գնել ապառիկ</span>
              </div>
            </div>
          </div>
          <div className={styles.rightBlock}>
            {treeInOne?.products?.map((item, index) => (
              <Product item={item} key={index} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
