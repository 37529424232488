import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

export default function Brand() {
  const [showBtns, setShowBtns] = useState(false);
  const [brandsState, setBrandsState] = useState(null);

  const { brands, currentLanguage } = useSelector(({ main }) => main);

  useEffect(() => {
    let array1 = [];
    let array2 = [];

    brands?.forEach((brand, index) => {
      if (array1.length >= 2) {
        array2.push(array1);
        array1 = [brand];
        if (index + 1 === brands?.length) {
          array2.push(array1);
        }
      } else {
        array1.push(brand);
      }
    });
    setBrandsState(array2);
  }, [brands]);

  const swiperRef = useRef();
  const slidesPerView =
    window.innerWidth > 1280
      ? 6
      : window.innerWidth > 600
      ? 4
      : window.innerWidth < 600
      ? 3
      : 5;
  return (
    <div className="wrapper">
      <div className={styles.titleBlock}>
        <h3 className="title">ԱՊՐԱՆՔԱՆԻՇԵՐ</h3>
        <span className={styles.moreBtn}>ՏԵՍՆԵԼ ԲՈԼՈՐԸ</span>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
          marginBottom: "20px",
        }}
        onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
        onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
      >
        {window.innerWidth > 768 && (
          <>
            <div
              className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
              style={{
                display: showBtns ? "flex" : "none",
              }}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <SliderNextArrowSvg />
            </div>
            <div
              className={`${styles.slideBtn} ${styles.slideNextArrow}`}
              style={{
                display: showBtns ? "flex" : "none",
              }}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <SliderNextArrowSvg />
            </div>
          </>
        )}
        <Swiper
          loop
          spaceBetween={window.innerWidth > 600 ? 24 : 10}
          slidesPerView={slidesPerView}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {!brandsState?.length
            ? Array.from({ length: 8 }).map((_, index) => (
                <SwiperSlide key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "20px",
                    }}
                  >
                    <LoadContent containerClassName={styles.loadContent} />
                    <LoadContent containerClassName={styles.loadContent} />
                  </div>
                </SwiperSlide>
              ))
            : brandsState.map((brandsArray, index) => (
                <SwiperSlide
                  style={{ width: "auto", display: "flex" }}
                  key={index}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: "20px",
                    }}
                  >
                    <a
                      className={styles.brandItem}
                      href={`https://vlv.am/brand/${brandsArray[0].slug}?lang=${currentLanguage}`}
                    >
                      <img
                        className={styles.brandItemImg}
                        src={
                          process.env.REACT_APP_STORAGE_URL +
                          brandsArray[0].logo
                        }
                        alt={brandsArray[0].name}
                      />
                    </a>
                    {brandsArray[1] && (
                      <a
                        className={styles.brandItem}
                        href={`https://vlv.am/brand/${brandsArray[1].slug}?lang=${currentLanguage}`}
                      >
                        <img
                          className={styles.brandItemImg}
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            brandsArray[1].logo
                          }
                          alt={brandsArray[1].name}
                        />
                      </a>
                    )}
                  </div>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </div>
  );
}
