import React, { useState } from "react";
import styles from "../style.module.css";
import EyeSvg from "../../../assets/SVG/EyeSvg";
import CloseEyeSvg from "../../../assets/SVG/CloseEyeSvg";
import { useDispatch } from "react-redux";
import { UserLogin } from "../../../store/MainSlice";

export default function Login({ setShowLogin, setForgotPassword }) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    setLoginError(!login);
    setPasswordError(!password);
    if (login && password) dispatch(UserLogin({ login, password }));
  };
  return (
    <>
      <span className={styles.subTitle}>
        Մուտքագրեք Ձեր անունը և գաղտնաբառը
      </span>
      <input
        type="email"
        placeholder="Էլ․հասցե"
        className={`${styles.input} ${loginError && styles.inputError}`}
        value={login}
        onChange={(e) => setLogin(e.target.value)}
      />
      <div className={styles.passwordContainer}>
        <input
          type={showPass ? "text" : "password"}
          placeholder="Գաղտնաբառ"
          className={`${styles.input} ${passwordError && styles.inputError}`}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <span className={styles.eye} onClick={() => setShowPass(!showPass)}>
          {!showPass ? <CloseEyeSvg /> : <EyeSvg />}
        </span>
      </div>
      <span
        className={styles.forgotPassword}
        onClick={() => setForgotPassword(true)}
      >
        Մոռացել ե՞ք գաղտնաբառը
      </span>
      <div className={styles.btn} onClick={handleSubmit}>
        <span>Մուտք</span>
      </div>
      <div className={styles.line} />
      <div
        className={`${styles.btn} ${styles.darkBtn}`}
        onClick={() => setShowLogin(false)}
      >
        <span>Գրանցվել</span>
      </div>
    </>
  );
}
