import React from "react";

export default function MinusSvg() {
  return (
    <svg
      width="19"
      height="3"
      viewBox="0 0 19 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.34302 1.45703H9.57159H17.8002"
        stroke="white"
        stroke-width="2.05714"
        stroke-linecap="round"
      ></path>
    </svg>
  );
}
