import React from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";

export default function Credit() {
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "Ապառիկ", value: "/credit", active: true }]}
      />
    </div>
  );
}
