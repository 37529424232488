import React from "react";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";

export default function FooterBaner() {
  const { footerBaner, currentLanguage } = useSelector(({ main }) => main);
  return (
    <div className="wrapper">
      {!footerBaner?.["image_" + currentLanguage] ? (
        <LoadContent
          containerStyle={{
            width: "100%",
            height: "auto",
            aspectRatio: "10 / 3.4",
          }}
        />
      ) : (
        <img
          style={{
            width: "100%",
            maxWidth: "100%",
            objectFit: "contain",
          }}
          src={
            process.env.REACT_APP_STORAGE_URL +
            footerBaner?.["image_" + currentLanguage]
          }
          alt="VLV"
        />
      )}
    </div>
  );
}
