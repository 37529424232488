import React, { useState } from "react";
import styles from "./style.module.css";

export default function DropDown({ active, data, setActive }) {
  const [show, setShow] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.btn} onClick={() => setShow(!show)}>
        <span>{active}</span> <span>+</span>
      </div>
      {show && (
        <div className={styles.openedContainer}>
          {data.map((item, index) => (
            <p
              key={index}
              onClick={() => setActive(item)}
              style={item.label === active ? { fontFamily: "bold" } : null}
            >
              {item.label}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}
