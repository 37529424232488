import React from "react";

export default function FacebookSvg(props) {
  return (
    <svg
      width="10"
      height="19"
      viewBox="0 0 10 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.26107 10.9375L9.76237 7.64323H6.57552V5.49479C6.57552 4.5638 7.00521 3.70443 8.4375 3.70443H9.9056V0.875651C9.9056 0.875651 8.58073 0.625 7.32747 0.625C4.71354 0.625 2.99479 2.23633 2.99479 5.10091V7.64323H0.0585938V10.9375H2.99479V18.9583H6.57552V10.9375H9.26107Z"
        fill="black"
      ></path>
    </svg>
  );
}
