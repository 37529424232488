import React, { memo, useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import ArmFlagSvg from "../../../../assets/SVG/ArmFlagSvg";
import USAFlagSvg from "../../../../assets/SVG/USAFlagSvg";
import RuFlagSvg from "../../../../assets/SVG/RuFlagSvg";
import ButtonArrowSvg from "../../../../assets/SVG/ButtonArrowSvg";
import DropDown from "../../../DropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentCurrency,
  setCurrentLanguage,
} from "../../../../store/MainSlice";
import UseOutSideClick from "../../../../helpers/UseOutSideClick";

const languagesData = [
  {
    label: "Հայերեն",
    value: "hy",
  },
  {
    label: "English",
    value: "en",
  },
  {
    label: "Русский",
    value: "ru",
  },
];
const currencyData = [
  {
    label: "(֏) Դրամ",
    value: "amd",
  },
  {
    label: "($) Dollars",
    value: "usd",
  },
  {
    label: "(руб) Рубли",
    value: "rub",
  },
];

export default memo(function Currency({ shortBtn = false }) {
  const [opened, setOpened] = useState(false);
  const { currentLanguage, currentCurrency } = useSelector(({ main }) => main);
  const dispatch = useDispatch();

  const languageFlags = {
    hy: <ArmFlagSvg />,
    en: <USAFlagSvg />,
    ru: <RuFlagSvg />,
  };
  const containerRef = useRef();

  UseOutSideClick(containerRef, () => setOpened(false));

  return (
    <div className={styles.container}>
      <div
        className={`${styles.btn} ${shortBtn && styles.shortBtn}`}
        onClick={() => setOpened(!opened)}
      >
        <div className={styles.langFlag}>{languageFlags[currentLanguage]}</div>
        <span style={{ fontSize: "14px" }}>/</span>
        <span className={styles.btnCurrencyText}>{currentCurrency}</span>
        <ButtonArrowSvg />
      </div>
      {opened && (
        <div className={styles.openedContainer} ref={containerRef}>
          <p>Լեզու</p>
          <DropDown
            active={
              languagesData.find((e) => e.value === currentLanguage)?.label
            }
            data={languagesData}
            setActive={(item) => {
              dispatch(setCurrentLanguage(item.value));
            }}
          />
          <p>Արժույթ</p>
          <DropDown
            active={
              currencyData.find((e) => e.value === currentCurrency)?.label
            }
            data={currencyData}
            setActive={(item) => {
              dispatch(setCurrentCurrency(item.value));
            }}
          />
        </div>
      )}
    </div>
  );
});
