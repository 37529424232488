import React, {
  useState,
  memo,
  useRef,
  useLayoutEffect,
  useEffect,
} from "react";
import styles from "./style.module.css";
import FacebookSvg from "../../assets/SVG/FacebookSvg";
import InstagramSvg from "../../assets/SVG/InstagramSvg";
import WhatsappSvg from "../../assets/SVG/WhatsappSvg";
import ViberSvg from "../../assets/SVG/ViberSvg";
import ButtonArrowSvg from "../../assets/SVG/ButtonArrowSvg";
import PhoneSvg from "../../assets/SVG/PhoneSvg";
import Currency from "./components/Currency";
import CatalogSvg from "../../assets/SVG/CatalogSvg";
import UserSvg from "../../assets/SVG/UserSvg";
import CompareSvg from "../../assets/SVG/CompareSvg";
import HeartSvg from "../../assets/SVG/HeartSvg";
import ShopCartSvg from "../../assets/SVG/ShopCartSvg";
import HeaderCategorys from "./components/HeaderCategorys";
import Catalog from "./components/Catalog";
import { NavLink } from "react-router-dom";
import MobileHeader from "./MobileHeader";
import CatalogMobile from "./components/CatalogMobile";
import { useDispatch, useSelector } from "react-redux";
import {
  getSearchInfo,
  setShopCartPositions,
  setShowLoginModal,
} from "../../store/MainSlice";
import UserMenu from "./components/UserMenu";
import UseOutSideClick from "../../helpers/UseOutSideClick";
import SearchModal from "./components/SearchModal";

export default memo(function Header() {
  const [headLineWidth, setHeadLineWidth] = useState(0);
  const [showPhones, setShowPhones] = useState(false);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  const { isAuthed, favoritesCount, comparesCount, shopCartCount } =
    useSelector(({ main }) => main);

  const dispatch = useDispatch();

  // const menuData = [
  //   { value: "contacts", label: "Մեր խանութները" },
  //   { value: "blog", label: "Բլոգ" },
  //   { value: "job", label: "Աշխատանք" },
  //   { value: "about-us", label: "Մեր մասին" },
  //   { value: "delivery", label: "Առաքում" },
  //   { value: "credit", label: "Ապառիկ" },
  //   { value: "contacts", label: "Կապ" },
  // ];
  const menuData = [
    { value: "https://vlv.am/contact", label: "Մեր խանութները" },
    { value: "https://vlv.am/blog", label: "Բլոգ" },
    { value: "https://vlv.am/job", label: "Աշխատանք" },
    { value: "https://vlv.am/about-us", label: "Մեր մասին" },
    { value: "https://vlv.am/delivery", label: "Առաքում" },
    { value: "https://vlv.am/credit", label: "Ապառիկ" },
    { value: "https://vlv.am/contact", label: "Կապ" },
  ];

  const shopCartRef = useRef();
  const openedPhonesRef = useRef();
  UseOutSideClick(openedPhonesRef, () => setShowPhones(false));

  useLayoutEffect(() => {
    const positions = shopCartRef.current?.getBoundingClientRect();
    dispatch(
      setShopCartPositions({ top: positions?.top, left: positions?.left })
    );
  }, [shopCartRef]);

  if (window.innerWidth <= 992) return <MobileHeader />;

  document.onscroll = function () {
    var pos = getVerticalScrollPercentage(document.body);
    setHeadLineWidth(Math.round(pos));
  };

  function getVerticalScrollPercentage(elm) {
    var p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }

  return (
    <>
      {window.innerWidth > 1024 ? (
        <Catalog openCatalog={openCatalog} setOpenCatalog={setOpenCatalog} />
      ) : (
        <CatalogMobile
          openCatalog={openCatalog}
          setOpenCatalog={setOpenCatalog}
        />
      )}

      <header className={styles.container}>
        <div
          className={styles.headLine}
          style={{ width: headLineWidth + "%" }}
        />
        <div className={styles.wrapper}>
          <div className={styles.headerThreeBlocks}>
            <div className={styles.headerTop}>
              <div className={styles.leftContent}>
                {menuData.map((item, index) => (
                  // <NavLink
                  //   to={item.value}
                  //   key={index}
                  //   className={styles.menuItem}
                  // >
                  //   {item.label}
                  // </NavLink>
                  <a href={item.value} key={index} className={styles.menuItem}>
                    {item.label}
                  </a>
                ))}
              </div>
              <div className={styles.rightContent}>
                <div className={styles.socIconsContainer}>
                  <a
                    href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d"
                    aria-label="facebook"
                  >
                    <FacebookSvg className={styles.socIcon} />
                  </a>
                  <a
                    href="https://www.instagram.com/vlv_electronics/"
                    aria-label="instagram"
                  >
                    <InstagramSvg className={styles.socIcon} />
                  </a>
                  <a href="https://wa.me/37491296655" aria-label="whatsapp">
                    <WhatsappSvg className={styles.socIcon} />
                  </a>
                  <a
                    href="viber://chat?number=+37491296655/"
                    aria-label="viber"
                  >
                    <ViberSvg className={styles.socIcon} />
                  </a>
                </div>
                <Currency />
                <div className={styles.phones}>
                  <PhoneSvg />
                  <a href="tel:010349944" className={styles.phoneBtn}>
                    010-34-99-44
                  </a>
                  <ButtonArrowSvg onClick={() => setShowPhones(!showPhones)} />
                  {showPhones && (
                    <div className={styles.phonesOpened} ref={openedPhonesRef}>
                      <a href="tel:010349944">010-34-99-44</a>
                      <a href="tel:010449968">010-44-99-68</a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={styles.headerBottom}>
              <NavLink to="/">
                <img
                  className={styles.logo}
                  src={require("../../assets/logo.png")}
                  alt="VLV"
                />
              </NavLink>
              <div
                className={styles.catalogBtn}
                onClick={() => setOpenCatalog(true)}
              >
                <CatalogSvg />
                <span>Բաժիններ</span>
              </div>
              <div className={styles.searchContainer}>
                <input
                  placeholder="Փնտրիր որ գտնես"
                  className={styles.searchInput}
                  onChange={(e) => {
                    dispatch(getSearchInfo(e.target.value));
                  }}
                />
                <span className={styles.searchBtn}>Որոնել</span>
                <SearchModal />
              </div>
              <div
                className={styles.userBtn}
                onClick={() => {
                  if (isAuthed) {
                    setShowUserMenu(!showUserMenu);
                  } else {
                    dispatch(setShowLoginModal(true));
                  }
                }}
              >
                <UserSvg />
                {showUserMenu && <UserMenu />}
              </div>
              <div className={styles.compareAndFavContainer}>
                <a href="https://vlv.am/compare" aria-label="compare">
                  {comparesCount > 0 && (
                    <span className={styles.count}>{comparesCount}</span>
                  )}
                  <CompareSvg />
                </a>
                <span className={styles.line} />
                <a href="https://vlv.am/favorite" aria-label="heart">
                  <HeartSvg active={favoritesCount > 0} />
                  {favoritesCount > 0 && (
                    <span className={styles.count}>{favoritesCount}</span>
                  )}
                </a>
              </div>
              <a href="https://vlv.am/cart">
                <div
                  className={`${styles.shopCartBtn} ${
                    shopCartCount?.count > 0 && styles.shopCartBtnActive
                  }`}
                  ref={shopCartRef}
                >
                  <ShopCartSvg />
                  <div className={styles.shopCartInfo}>
                    <span>
                      Զամբյուղ {shopCartCount?.count > 0 && shopCartCount.count}
                    </span>
                    <span>
                      {shopCartCount?.count > 0
                        ? shopCartCount.price.toLocaleString("en-US")
                        : 0}{" "}
                      ֏
                    </span>
                  </div>
                </div>
              </a>
            </div>
            <HeaderCategorys />
          </div>
        </div>
      </header>
    </>
  );
});
