import React, { useEffect } from "react";
import styles from "./style.module.css";
import CloseSvg from "../../../../assets/SVG/CloseSvg";
import FacebookSvg from "../../../../assets/SVG/FacebookSvg";
import InstagramSvg from "../../../../assets/SVG/InstagramSvg";
import WhatsappSvg from "../../../../assets/SVG/WhatsappSvg";
import ViberSvg from "../../../../assets/SVG/ViberSvg";
import Currency from "../Currency";
import PhoneSvg from "../../../../assets/SVG/PhoneSvg";
export default function MobileMenu({ openMenu, setOpenMenu }) {
  useEffect(() => {
    if (openMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openMenu]);
  // const menuData = [
  //   { value: "contacts", label: "Մեր խանութները" },
  //   { value: "blog", label: "Բլոգ" },
  //   { value: "job", label: "Աշխատանք" },
  //   { value: "about-us", label: "Մեր մասին" },
  //   { value: "delivery", label: "Առաքում" },
  //   { value: "credit", label: "Ապառիկ" },
  //   { value: "contacts", label: "Կապ" },
  // ];
  const menuData = [
    { value: "https://vlv.am/contact", label: "Մեր խանութները" },
    { value: "https://vlv.am/blog", label: "Բլոգ" },
    { value: "https://vlv.am/job", label: "Աշխատանք" },
    { value: "https://vlv.am/about-us", label: "Մեր մասին" },
    { value: "https://vlv.am/delivery", label: "Առաքում" },
    { value: "https://vlv.am/credit", label: "Ապառիկ" },
    { value: "https://vlv.am/contact", label: "Կապ" },
  ];
  return (
    <div className={`${styles.container} ${openMenu && styles.openMenu}`}>
      <div className={styles.headContent}>
        <div className={styles.socIconsContainer}>
          <a href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d">
            <FacebookSvg className={styles.socIcon} />
          </a>
          <a href="https://www.instagram.com/vlv_electronics/">
            <InstagramSvg className={styles.socIcon} />
          </a>
          <a href="https://wa.me/37491296655">
            <WhatsappSvg className={styles.socIcon} />
          </a>
          <a href="viber://chat?number=+37491296655/">
            <ViberSvg className={styles.socIcon} />
          </a>
        </div>
        <Currency />
        <CloseSvg
          width="20px"
          height="20px"
          className={styles.closeBtn}
          onClick={() => setOpenMenu(false)}
        />
      </div>
      <div>
        <a className={styles.phone} href="tel:010349944">
          <PhoneSvg width="16" height="16" />
          010-34-99-44
        </a>
      </div>
      <div className={styles.menuContainer}>
        {menuData.map((item, index) => (
          // <NavLink
          //   onClick={() => setOpenMenu(false)}
          //   to={item.value}
          //   key={index}
          //   className={styles.menuItem}
          // >
          //   {item.label}
          // </NavLink>
          <a
            onClick={() => setOpenMenu(false)}
            href={item.value}
            key={index}
            className={styles.menuItem}
          >
            {item.label}
          </a>
        ))}
      </div>
    </div>
  );
}
