import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import ShopCartSvg from "../../../../../../assets/SVG/ShopCartSvg";
import {
  addCardStore,
  addCompare,
  addComparesCount,
  addFavoritesCount,
  addShopCartCount,
  addWishList,
  removeComparesCount,
  removeFavoritesCount,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setShopCartProductAnimation,
} from "../../../../../../store/MainSlice";
import CompareSvg from "../../../../../../assets/SVG/CompareSvg";
import HeartSvg from "../../../../../../assets/SVG/HeartSvg";
import { credit36Month } from "../../../../../../helpers/creditCalculator";

export default function Product({ product }) {
  const [favorite, setFavorite] = useState(false);
  const [compareActive, setCompareActive] = useState(false);
  const [active, setActive] = useState(0);
  const [mouseIn, setMouseIn] = useState(false);

  const currentLanguage = useSelector(({ main }) => main.currentLanguage);

  const dispatch = useDispatch();
  const productImageRef = useRef();

  return (
    <div
      className={styles.container}
      onMouseMove={() => setMouseIn(true)}
      onMouseOut={() => setMouseIn(false)}
    >
      <div className={styles.leftBlock} ref={productImageRef}>
        <div className={styles.logo}>
          <img
            src={
              process.env.REACT_APP_STORAGE_URL + product?.product?.brand.logo
            }
            alt={product?.product?.brand?.name}
          />
        </div>
        <a
          className={styles.imgs}
          href={`https://vlv.am/Product/${product?.id}?lang=${currentLanguage}`}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              display: "flex",
              flexDirection: "row",
              marginBottom: "5px",
            }}
          >
            {(product?.product?.gallary_images.length
              ? product?.product?.gallary_images
              : Array.from({ length: 1 })
            ).map((_, index) => (
              <div
                key={index}
                style={{
                  cursor: "pointer",
                  background: "transparent",
                  width: "100%",
                  height: "100%",
                  marginRight: "5px",
                  borderBottom:
                    "1.5px solid " +
                    (index === active ? "rgb(227, 19, 53)" : "#d9d9d9"),
                  zIndex: 99,
                  opacity: +mouseIn,
                }}
                onMouseMove={() => setActive(index)}
              />
            ))}
          </div>
          {product?.product?.gallary_images.length ? (
            product?.product?.gallary_images.map((item, index) => (
              <div
                style={{
                  zIndex: index,
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  right: 0,
                  left: 0,
                  paddingBottom: "5px",
                }}
                key={index}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    opacity: active === index ? 1 : 0,
                  }}
                  alt={
                    product?.product?.brand?.name +
                    " " +
                    product?.product?.product_name
                  }
                  src={process.env.REACT_APP_STORAGE_URL + item.images_source}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = require("../../../../../../assets/defaultImg.png");
                  }}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                zIndex: 1,
                position: "absolute",
                top: 0,
                bottom: 0,
                right: 0,
                left: 0,
                paddingBottom: "5px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                alt={
                  product?.product?.brand?.name +
                  " " +
                  product?.product?.product_name
                }
                src={
                  process.env.REACT_APP_STORAGE_URL +
                  product?.product?.thumbnail_image_source
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = require("../../../../../../assets/defaultImg.png");
                }}
              />
            </div>
          )}
        </a>
      </div>
      <div className={styles.rightBlock}>
        <a
          href={`https://vlv.am/Product/${product?.id}?lang=${currentLanguage}`}
        >
          <div className={styles.categoryName}>Հեռուստացույցներ</div>
          <p className={styles.productName}>
            {product?.product?.brand?.name +
              " " +
              product?.product?.product_name}
          </p>
          {!product?.promo_price ? (
            <p className={styles.price}>
              {product?.product?.average_price?.toLocaleString("en-US")} ֏
            </p>
          ) : (
            <div className={styles.promoPriceContainer}>
              <p className={styles.newPrice}>
                {product?.promo_price?.toLocaleString("en-US")} ֏
              </p>
              <div className={styles.oldPriceContainer}>
                <p className={styles.oldPrice}>
                  {product?.promo_price?.toLocaleString("en-US")} ֏
                </p>
                <span className={styles.line} />
              </div>
            </div>
          )}
          {credit36Month(
            product?.promo_price || product?.skus[0]?.selling_price
          ) && (
            <div className={styles.priceMounth}>
              {credit36Month(
                product?.promo_price || product?.skus[0]?.selling_price
              )?.toLocaleString("en-US")}{" "}
              ֏ / Ամիս
            </div>
          )}
        </a>
        <div className={styles.btnsContainer}>
          <div
            className={styles.cardBtn}
            onClick={() => {
              dispatch(
                addShopCartCount(
                  product?.promo_price || product?.skus[0]?.selling_price
                )
              );
              if (
                product?.product?.gallary_images[0] ||
                product?.product?.thumbnail_image_source
              ) {
                const { top, left } =
                  productImageRef.current?.getBoundingClientRect();

                dispatch(
                  setShopCartProductAnimation({
                    image:
                      product?.product?.gallary_images?.[0]?.images_source ||
                      product?.product?.thumbnail_image_source,
                    position: {
                      top,
                      left,
                    },
                  })
                );
              }
              dispatch(
                addCardStore({
                  price:
                    product?.promo_price || product?.skus[0]?.selling_price,
                  qty: 1,
                  product_id: product?.id,
                  seller_id: 1,
                  shipping_method_id: 0,
                  type: "product",
                  is_buy_now: "no",
                })
              );
            }}
          >
            <ShopCartSvg className={styles.cardBtnSvg} />
            <span>Ավելացնել</span>
          </div>
          <div
            onClick={() => {
              if (
                (product?.product?.gallary_images[0] ||
                  product?.product?.thumbnail_image_source) &&
                !favorite
              ) {
                const { top, left } =
                  productImageRef.current?.getBoundingClientRect();
                dispatch(
                  setHeartProductAnimation({
                    image:
                      product?.product?.gallary_images?.[0]?.images_source ||
                      product?.product?.thumbnail_image_source,
                    position: {
                      top,
                      left,
                    },
                  })
                );
              }
              if (!favorite) {
                dispatch(addFavoritesCount());
              } else {
                dispatch(removeFavoritesCount());
              }
              setFavorite(!favorite);
              dispatch(
                addWishList({
                  product_id: product.id,
                })
              );
            }}
          >
            <HeartSvg active={favorite} />
          </div>
          <div
            onClick={() => {
              if (
                (product?.product?.gallary_images[0] ||
                  product?.product?.thumbnail_image_source) &&
                !compareActive
              ) {
                const { top, left } =
                  productImageRef.current?.getBoundingClientRect();
                dispatch(
                  setCompareProductAnimation({
                    image:
                      product?.product?.gallary_images?.[0]?.images_source ||
                      product?.product?.thumbnail_image_source,
                    position: {
                      top,
                      left,
                    },
                  })
                );
              }
              if (!compareActive) {
                dispatch(addComparesCount());
              } else {
                dispatch(removeComparesCount());
              }
              setCompareActive(!compareActive);
              dispatch(
                addCompare({
                  product_sku_id: product.skus?.[0].id,
                  data_type: product.product.product_type,
                })
              );
            }}
          >
            <CompareSvg active={compareActive} />
          </div>
        </div>
      </div>
    </div>
  );
}
