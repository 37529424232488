import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import Sliders from "./components/Sliders";
import { useDispatch, useSelector } from "react-redux";
import { getMainInfo, getToken } from "../../store/MainSlice";
import Sales from "./components/Sales";
import FeatureCategories from "./components/FeatureCategories";
import WorkInformations from "./components/WorkInformations";
import BestDealProduct from "./components/BestDealProduct";
import BanerSlider from "./components/BanerSlider";
import Blog from "./components/Blog";
import Brand from "./components/Brand";
import FooterBaner from "./components/FooterBaner";
import ButtonArrowSvg from "../../assets/SVG/ButtonArrowSvg";
import TopRatingProduct from "./components/TopRatingProduct";
import TopPicksProduct from "./components/TopPicksProduct/index";
import Paralax from "./components/Paralax";
import TreeInOne from "./components/TreeInOne";
import SpecialOffer from "./components/SpecialOffer";
export default function Home() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  const { token, haveMainInfo } = useSelector(({ main }) => main);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (!token) {
  //     dispatch(getToken());
  //   } else if (!haveMainInfo) {
  //     dispatch(getMainInfo());
  //   }
  // }, [token]);
  const scrollListnerFunc = (e) => {
    if (document.body.parentNode.scrollTop > 500) {
      setShowTopBtn(true);
    } else if (document.body.parentNode.scrollTop < 500) {
      setShowTopBtn(false);
    }
  };
  useEffect(() => {
    // dispatch(getCsrfToken());
    dispatch(getMainInfo());
    document.addEventListener("scroll", scrollListnerFunc);
    return () => {
      document.removeEventListener("scroll", scrollListnerFunc);
    };
  }, []);

  return (
    <>
      {window.innerWidth > 1440 && <Paralax />}

      <div className={styles.container}>
        <div style={{ overflow: "hidden" }}>
          <div
            className={styles.topBtn}
            style={{ opacity: +showTopBtn }}
            onClick={() =>
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
            }
          >
            <ButtonArrowSvg className={styles.arrow} />
          </div>

          <Sliders />
          <WorkInformations />
          <FeatureCategories />
          <SpecialOffer />
          <Sales />
          <BestDealProduct />
          <BanerSlider />
          <TopRatingProduct />
          <TreeInOne />
          <TopPicksProduct />
          <Blog />
          <Brand />
          <FooterBaner />
        </div>
      </div>
    </>
  );
}
