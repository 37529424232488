import React, { memo, useEffect } from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import { useDispatch, useSelector } from "react-redux";
import LoadContent from "../../../LoadContent";
import { getHaedaerCategorys } from "../../../../store/MainSlice";

export default memo(function HeaderCategorys() {
  const { headerCategorys, currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!headerCategorys.length) {
      dispatch(getHaedaerCategorys());
    }
  }, [headerCategorys]);
  return (
    <div className={styles.container}>
      <Swiper
        modules={[Scrollbar]}
        spaceBetween={20}
        slidesPerView={"auto"}
        scrollbar={{ draggable: true, dragSize: "auto" }}
        style={{ paddingBottom: "20px" }}
      >
        {!headerCategorys.length
          ? Array.from({ length: 7 }).map((_, index) => (
              <SwiperSlide
                style={{ width: "auto", display: "flex" }}
                key={index}
              >
                <LoadContent containerClassName={styles.loadContent} />
              </SwiperSlide>
            ))
          : headerCategorys.map((item, index) => (
              <SwiperSlide
                style={{ width: "auto", display: "flex", position: "relative" }}
                key={index}
              >
                {item?.icon ? (
                  <a
                    className={styles.slideItem}
                    href={`https://vlv.am/catalog/${item?.slug}?lang=${currentLanguage}`}
                  >
                    <img
                      className={styles.headerCategorysIcon}
                      src={process.env.REACT_APP_STORAGE_URL + item.icon}
                      alt={item.name_en}
                    />
                    <span>{item["name_" + currentLanguage]}</span>
                  </a>
                ) : (
                  <div className={styles.slideItem}>
                    <img
                      className={styles.headerCategorysLogo}
                      src={process.env.REACT_APP_STORAGE_URL + item.logo}
                      alt={"vlv"}
                    />
                  </div>
                )}
              </SwiperSlide>
            ))}
      </Swiper>
    </div>
  );
});
