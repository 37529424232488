import React, { Fragment } from "react";
import styles from "./style.module.css";
import HomeSvg from "./assets/HomeSvg";
import { NavLink } from "react-router-dom";

export default function FromToPage({ pages = [] }) {
  return (
    <div className={styles.container}>
      <NavLink to="/" className={styles.homeBtn}>
        <HomeSvg />
      </NavLink>

      {pages.map((item, index) => {
        return (
          <Fragment key={index}>
            <span className={styles.line} />
            <NavLink to={item.value}>
              <span
                className={styles.title}
                style={item.active ? { color: "rgb(227, 19, 53)" } : {}}
              >
                {item.label}
              </span>
            </NavLink>
          </Fragment>
        );
      })}
    </div>
  );
}
