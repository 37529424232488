import React from "react";

export default function ShopCartSvg(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.2282 3.70957C18.0179 3.45418 17.7547 3.24878 17.4572 3.10795C17.1596 2.96713 16.8351 2.89433 16.5067 2.89474H4.42094L4.38954 2.62918C4.32529 2.07719 4.06317 1.56825 3.65286 1.19885C3.24256 0.829445 2.7126 0.625259 2.16346 0.625L1.99751 0.625C1.79926 0.625 1.60913 0.704711 1.46894 0.846597C1.32876 0.988483 1.25 1.18092 1.25 1.38158C1.25 1.58224 1.32876 1.77467 1.46894 1.91656C1.60913 2.05845 1.79926 2.13816 1.99751 2.13816H2.16346C2.34655 2.13818 2.52326 2.20622 2.66008 2.32936C2.7969 2.4525 2.88431 2.62218 2.90573 2.80622L3.93431 11.6582C4.04109 12.5788 4.4781 13.4277 5.1624 14.0437C5.84669 14.6597 6.73063 15 7.64644 15H15.4527C15.6509 15 15.8411 14.9203 15.9813 14.7784C16.1214 14.6365 16.2002 14.4441 16.2002 14.2434C16.2002 14.0428 16.1214 13.8503 15.9813 13.7084C15.8411 13.5666 15.6509 13.4868 15.4527 13.4868H7.64644C7.18377 13.4855 6.73283 13.3394 6.35544 13.0685C5.97805 12.7976 5.69268 12.4152 5.53846 11.9737H14.4488C15.3251 11.9737 16.1735 11.6621 16.8459 11.0934C17.5183 10.5246 17.9718 9.73474 18.1273 8.86187L18.7141 5.56773C18.7726 5.24089 18.7595 4.90502 18.6755 4.5839C18.5915 4.26279 18.4388 3.96429 18.2282 3.70957V3.70957ZM17.2467 5.29914L16.6592 8.59329C16.5658 9.1176 16.2932 9.59197 15.8891 9.93334C15.485 10.2747 14.9751 10.4613 14.4488 10.4605H5.30075L4.59735 4.40789H16.5067C16.6165 4.40723 16.7251 4.43106 16.8248 4.47769C16.9244 4.52432 17.0127 4.59261 17.0834 4.6777C17.154 4.76278 17.2053 4.86258 17.2335 4.96999C17.2618 5.07739 17.2663 5.18978 17.2467 5.29914V5.29914Z"
        fill="#282828"
      ></path>
      <path
        d="M6.5625 18.75C7.42545 18.75 8.125 18.0504 8.125 17.1875C8.125 16.3246 7.42545 15.625 6.5625 15.625C5.69956 15.625 5 16.3246 5 17.1875C5 18.0504 5.69956 18.75 6.5625 18.75Z"
        fill="#282828"
      ></path>
      <path
        d="M14.0625 18.75C14.9254 18.75 15.625 18.0504 15.625 17.1875C15.625 16.3246 14.9254 15.625 14.0625 15.625C13.1996 15.625 12.5 16.3246 12.5 17.1875C12.5 18.0504 13.1996 18.75 14.0625 18.75Z"
        fill="#282828"
      ></path>
    </svg>
  );
}
