import React from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/css";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";

export default function WorkInformations() {
  const { workInformations, currentLanguage } = useSelector(({ main }) => main);
  return (
    <div className="wrapper">
      <div className={styles.container}>
        <Swiper
          modules={[Scrollbar]}
          spaceBetween={24}
          slidesPerView={"auto"}
          scrollbar={{ draggable: true, dragSize: "auto" }}
          style={{ paddingBottom: "15px" }}
        >
          {!workInformations?.length //!workInformations?.length
            ? Array.from({ length: 7 }).map((_, index) => (
                <SwiperSlide
                  style={{ width: "auto", display: "flex" }}
                  key={index}
                >
                  <LoadContent containerClassName={styles.loadContent} />
                </SwiperSlide>
              ))
            : workInformations.map((item, index) => (
                <SwiperSlide
                  style={{ width: "auto", display: "flex" }}
                  className={styles.slideItem}
                  key={index}
                >
                  <img
                    className={styles.slideItemImg}
                    src={process.env.REACT_APP_STORAGE_URL + item.icon}
                    alt={item.title_en}
                  />
                  <span className={styles.slideItemTitle}>
                    {item["title_" + currentLanguage]}
                  </span>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </div>
  );
}
