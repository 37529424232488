export default [
  {
    link: "https://vlv.am/profile/dashboard",
    label: "Անձնական էջ",
    icon: (
      <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.9688 8C17.9688 8.5625 17.5 9 16.9688 9H15.9688L16 14C16 14.0938 16 14.1875 16 14.25V14.75C16 15.4688 15.4375 16 14.75 16H14.25C14.1875 16 14.1562 16 14.125 16C14.0938 16 14.0312 16 14 16H12.25C11.5312 16 11 15.4688 11 14.75V12C11 11.4688 10.5312 11 10 11H8C7.4375 11 7 11.4688 7 12V14.75C7 15.4688 6.4375 16 5.75 16H4C3.9375 16 3.90625 16 3.84375 16C3.8125 16 3.78125 16 3.75 16H3.25C2.53125 16 2 15.4688 2 14.75V11.25C2 11.25 2 11.2188 2 11.1875V9H1C0.4375 9 0 8.5625 0 8C0 7.71875 0.09375 7.46875 0.3125 7.25L8.3125 0.28125C8.53125 0.0625 8.78125 0 9 0C9.21875 0 9.46875 0.09375 9.65625 0.25L17.625 7.25C17.875 7.46875 18 7.71875 17.9688 8Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
  {
    link: "https://vlv.am/profile/personal-info",
    label: "Անձնական տվյալներ",
    icon: (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 7C8.53125 7 10.2143 5.44141 10.2143 3.5C10.2143 1.58594 8.53125 0 6.5 0C4.43973 0 2.78571 1.58594 2.78571 3.5C2.78571 5.44141 4.43973 7 6.5 7ZM7.95089 8.3125H5.02009C2.23437 8.3125 0 10.4453 0 13.0703C0 13.5898 0.435268 14 0.986607 14H11.9844C12.5357 14 13 13.5898 13 13.0703C13 10.4453 10.7366 8.3125 7.95089 8.3125Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
  {
    link: "https://vlv.am/profile/my-addresses",
    label: "Իմ հասցեները",
    icon: (
      <svg
        width="11"
        height="14"
        viewBox="0 0 11 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.8125 13.6919C3.32292 11.912 0 7.66748 0 5.2577C0 2.35501 2.4349 0 5.5 0C8.53646 0 11 2.35501 11 5.2577C11 7.66748 7.64844 11.912 6.15885 13.6919C5.8151 14.1027 5.15625 14.1027 4.8125 13.6919ZM5.5 7.01027C6.5026 7.01027 7.33333 6.24352 7.33333 5.2577C7.33333 4.29927 6.5026 3.50513 5.5 3.50513C4.46875 3.50513 3.66667 4.29927 3.66667 5.2577C3.66667 6.24352 4.46875 7.01027 5.5 7.01027Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
  {
    link: "https://vlv.am/profile/order-history",
    label: "Պատվերի պատմություն",
    icon: (
      <svg
        width="17"
        height="15"
        viewBox="0 0 17 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.77778 4.6875V2.8125C3.77778 1.25977 5.01736 0 6.61111 0C8.17535 0 9.44444 1.25977 9.44444 2.8125V4.6875H11.3333C12.3663 4.6875 13.2222 5.53711 13.2222 6.5625H7.08333C5.75521 6.5625 4.72222 7.61719 4.72222 8.90625V13.5938C4.72222 13.7695 4.72222 13.916 4.75174 14.0625H1.88889C0.826389 14.0625 0 13.2422 0 12.1875V6.5625C0 5.53711 0.826389 4.6875 1.88889 4.6875H3.77778ZM5.19444 4.6875H8.02778V2.8125C8.02778 2.05078 7.37847 1.40625 6.61111 1.40625C5.81424 1.40625 5.19444 2.05078 5.19444 2.8125V4.6875ZM15.1111 7.5C16.1441 7.5 17 8.34961 17 9.375V13.125C17 14.1797 16.1441 15 15.1111 15H7.55556C6.49306 15 5.66667 14.1797 5.66667 13.125V9.375C5.66667 8.34961 6.49306 7.5 7.55556 7.5H15.1111ZM9.44444 9.84375C9.44444 9.60938 9.20833 9.375 8.97222 9.375C8.7066 9.375 8.5 9.60938 8.5 9.84375V10.3125C8.5 11.8652 9.73958 13.125 11.3333 13.125C12.8976 13.125 14.1667 11.8652 14.1667 10.3125V9.84375C14.1667 9.60938 13.9306 9.375 13.6944 9.375C13.4288 9.375 13.2222 9.60938 13.2222 9.84375V10.3125C13.2222 11.3672 12.3663 12.1875 11.3333 12.1875C10.2708 12.1875 9.44444 11.3672 9.44444 10.3125V9.84375Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
  {
    link: "https://vlv.am/profile/virtual-products",
    label: "Վիրտուալ ապրանքներ",
    icon: (
      <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.25 3.0625C3.25 1.39453 4.70089 0 6.5 0C8.27009 0 9.75 1.39453 9.75 3.0625V4.375H11.6071C12.3616 4.375 13 4.97656 13 5.6875V11.375C13 12.8242 11.7522 14 10.2143 14H2.78571C1.21875 14 0 12.8242 0 11.375V5.6875C0 4.97656 0.609375 4.375 1.39286 4.375H3.25V3.0625ZM4.64286 4.375H8.35714V3.0625C8.35714 2.10547 7.51562 1.3125 6.5 1.3125C5.45536 1.3125 4.64286 2.10547 4.64286 3.0625V4.375ZM3.94643 7C4.32366 7 4.64286 6.72656 4.64286 6.34375C4.64286 5.98828 4.32366 5.6875 3.94643 5.6875C3.54018 5.6875 3.25 5.98828 3.25 6.34375C3.25 6.72656 3.54018 7 3.94643 7ZM9.05357 5.6875C8.64732 5.6875 8.35714 5.98828 8.35714 6.34375C8.35714 6.72656 8.64732 7 9.05357 7C9.4308 7 9.75 6.72656 9.75 6.34375C9.75 5.98828 9.4308 5.6875 9.05357 5.6875Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
  {
    link: "https://vlv.am/profile/my-wishlist",
    label: "Հավանած ապրանքնր",
    icon: (
      <svg
        width="13"
        height="12"
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.47797 0C8.86827 0.00938677 8.27187 0.17785 7.74899 0.488377C7.22612 0.798904 6.79528 1.2405 6.5 1.76857C6.20472 1.2405 5.77388 0.798904 5.25101 0.488377C4.72813 0.17785 4.13173 0.00938677 3.52203 0C2.5501 0.0417975 1.63439 0.462643 0.974945 1.17059C0.315502 1.87855 -0.0340622 2.81606 0.00262121 3.7783C0.00262121 7.40923 5.93473 11.6029 6.18704 11.7808L6.5 12L6.81296 11.7808C7.06527 11.6039 12.9974 7.40923 12.9974 3.7783C13.0341 2.81606 12.6845 1.87855 12.0251 1.17059C11.3656 0.462643 10.4499 0.0417975 9.47797 0ZM6.5 10.6805C4.73867 9.37823 1.08552 6.17873 1.08552 3.7783C1.0485 3.1002 1.28389 2.43512 1.74033 1.92811C2.19676 1.42111 2.83723 1.11331 3.52203 1.07186C4.20684 1.11331 4.84731 1.42111 5.30374 1.92811C5.76018 2.43512 5.99556 3.1002 5.95855 3.7783H7.04145C7.00443 3.1002 7.23982 2.43512 7.69626 1.92811C8.1527 1.42111 8.79316 1.11331 9.47797 1.07186C10.1628 1.11331 10.8032 1.42111 11.2597 1.92811C11.7161 2.43512 11.9515 3.1002 11.9145 3.7783C11.9145 6.1798 8.26133 9.37823 6.5 10.6805Z"
          fill="#E31335"
        ></path>
        <path
          d="M6.5 10.6805C4.73867 9.37823 1.08552 6.17873 1.08552 3.7783C1.0485 3.1002 1.28389 2.43512 1.74033 1.92811C2.19676 1.42111 2.83723 1.11331 3.52203 1.07186C4.20684 1.11331 4.84731 1.42111 5.30374 1.92811C5.76018 2.43512 5.99556 3.1002 5.95855 3.7783H7.04145C7.00443 3.1002 7.23982 2.43512 7.69626 1.92811C8.1527 1.42111 8.79316 1.11331 9.47797 1.07186C10.1628 1.11331 10.8032 1.42111 11.2597 1.92811C11.7161 2.43512 11.9515 3.1002 11.9145 3.7783C11.9145 6.1798 8.26133 9.37823 6.5 10.6805Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
  {
    link: "https://vlv.am/secret-logout",
    label: "Դուրս գալ",
    icon: (
      <svg
        width="17"
        height="14"
        viewBox="0 0 17 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5 12H3.5C2.9375 12 2.5 11.5625 2.5 11V3C2.5 2.46875 2.9375 2 3.5 2H5.5C6.03125 2 6.5 1.5625 6.5 1C6.5 0.46875 6.03125 0 5.5 0H3.5C1.8125 0 0.5 1.34375 0.5 3V11C0.5 12.6875 1.8125 14 3.5 14H5.5C6.03125 14 6.5 13.5625 6.5 13C6.5 12.4688 6.03125 12 5.5 12ZM16.1875 6.3125L12.1875 2.3125C11.8125 1.90625 11.1562 1.90625 10.7812 2.3125C10.375 2.6875 10.375 3.34375 10.7812 3.71875L13.0625 6H6.5C5.9375 6 5.5 6.46875 5.5 7C5.5 7.5625 5.9375 8 6.5 8H13.0625L10.7812 10.3125C10.375 10.6875 10.375 11.3438 10.7812 11.7188C11.1562 12.125 11.8125 12.125 12.1875 11.7188L16.1875 7.71875C16.5938 7.34375 16.5938 6.6875 16.1875 6.3125Z"
          fill="#E31335"
        ></path>
      </svg>
    ),
  },
];
