import React from "react";
import FromToPage from "../../components/FromToPage";

export default function AboutUs() {
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "Մեր մասին", value: "/about-us", active: true }]}
      />
    </div>
  );
}
