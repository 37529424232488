import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import CloseSvg from "../../../../assets/SVG/CloseSvg";
import { useDispatch, useSelector } from "react-redux";
import MenuArrowSvg from "./assets/MenuArrowSvg";
import { Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import { getMenuData } from "../../../../store/MainSlice";

export default function Catalog({ openCatalog, setOpenCatalog }) {
  const [activeMenu, setActiveMenu] = useState(0);
  const { menuData, currentLanguage } = useSelector(({ main }) => main);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!menuData.length) {
      dispatch(getMenuData());
    }
  }, [menuData]);
  useEffect(() => {
    if (openCatalog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openCatalog]);

  return (
    <div className={`${styles.container} ${openCatalog && styles.openMenu}`}>
      <div className={styles.catalogList}>
        {menuData.map((elm, index) => {
          if (elm.from === "category") {
            return (
              <div
                className={`${styles.menuItem} ${
                  activeMenu === index ? styles.menuItemActive : ""
                }`}
                onClick={() => setActiveMenu(index)}
                key={index}
              >
                <div>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + elm.item.icon}
                    alt={elm.item.name_en}
                  />
                  <a>{elm.item["name_" + currentLanguage]}</a>
                </div>
                <MenuArrowSvg />
              </div>
            );
          } else {
            return (
              <div className={styles.menuItem} key={index}>
                <div>
                  <img
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      elm.item.header_category_logo
                    }
                    alt="VLV Baner"
                  />
                  <a>{elm.item["title_" + currentLanguage]}</a>
                </div>
                <span />
              </div>
            );
          }
        })}
      </div>
      <div className={styles.catalogContent}>
        <div className={styles.banner}>
          <Swiper
            loop
            autoplay
            modules={[Autoplay]}
            pagination={{ clickable: true }}
            grabCursor={true}
            centeredSlides={true}
          >
            {menuData?.[activeMenu]?.item?.category_slider_image.map(
              (item, index) => (
                <SwiperSlide key={index}>
                  <picture>
                    <img
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        item["image_" + currentLanguage]
                      }
                      alt={item.title_en}
                      className={styles.banerImage}
                    />
                  </picture>
                </SwiperSlide>
              )
            )}
          </Swiper>
        </div>
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <img
              src={
                process.env.REACT_APP_STORAGE_URL +
                menuData?.[activeMenu]?.item.icon
              }
              alt={menuData?.[activeMenu]?.item.name_en}
            />
            <h4 className={styles.title}>
              {menuData?.[activeMenu]?.item["name_" + currentLanguage]}
            </h4>
            <span className={styles.count}>
              {menuData?.[activeMenu]?.product_count}
            </span>
          </div>
          <div className={styles.subCategories}>
            {menuData[activeMenu]?.item?.sub_categories.map((item, index) => (
              <div className={styles.subCategory} key={index}>
                <div className={styles.subCategoryTitle}>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + item.icon}
                    alt={item.name_en}
                  />
                  <h6>{item["name_" + currentLanguage]}</h6>
                </div>
                {item?.sub_categories.map((subItem) => (
                  <div className={styles.subCategorySubs}>
                    <div className={styles.subCategoryTitle}>
                      <img
                        src={process.env.REACT_APP_STORAGE_URL + subItem.icon}
                        alt={subItem.name_en}
                      />
                      <h6>{subItem["name_" + currentLanguage]}</h6>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      <CloseSvg
        className={styles.closeBtn}
        onClick={() => setOpenCatalog(false)}
      />
    </div>
  );
}
