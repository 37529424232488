import { useEffect } from "react";

export default function UseOutSideClick(ref, callBack) {
  useEffect(() => {
    if (ref.current && callBack) {
      function handleClickOutside(event) {
        if (!ref.current.contains(event.target)) {
          callBack();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [ref, callBack]);
}
