import React, { useLayoutEffect, useRef, useState } from "react";
import styles from "./style.module.css";

import Paralax1Svg from "./assets/Paralax1Svg";
import Paralax2Svg from "./assets/Paralax2Svg";
import Paralax3Svg from "./assets/Paralax3Svg";
import Paralax4Svg from "./assets/Paralax4Svg";

export default function Paralax() {
  const [paralax, setParalax] = useState({
    x: 0,
    y: 0,
  });
  const listnerFunc = (e) => {
    const { clientX, clientY } = e;
    setParalax({
      x: -clientX,
      y: -clientY,
    });
  };
  useLayoutEffect(() => {
    document.addEventListener("mousemove", listnerFunc);
    return () => {
      document.removeEventListener("mousemove", listnerFunc);
    };
  });

  return (
    <div className={styles.container}>
      <Paralax1Svg
        className={styles.leftTop}
        style={{
          transform: `translate(${paralax.x / 10}px, ${paralax.y / 20}px)`,
        }}
      />
      <Paralax2Svg
        className={styles.leftBottom}
        style={{
          transform: `translate(${paralax.x / 20}px, ${paralax.y / 20}px)`,
        }}
      />

      <Paralax3Svg
        className={styles.rightTop}
        style={{
          transform: `translate(${paralax.x / 20}px, ${paralax.y / 20}px)`,
        }}
      />
      <Paralax4Svg
        className={styles.rightBottom}
        style={{
          transform: `translate(${paralax.x / 15}px, ${paralax.y / 20}px)`,
        }}
      />
    </div>
  );
}
