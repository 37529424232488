import React from "react";
// import { useParams } from "react-router-dom";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";
import CompareSvg from "../../assets/SVG/CompareSvg";
import HeartSvg from "../../assets/SVG/HeartSvg";
import ShareSvg from "../../assets/SVG/ShareSvg";

export default function ProductPage() {
  // const { productId } = useParams();
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "TOSHIBA DW10F1CIS(W)", value: "/", active: true }]}
      />
      <div className={styles.main}>
        <div className={styles.sliderBlock}>
          <div className={styles.imgBlock}>
            <img src="https://vlv.am/public/uploads/images/20-02-2023/63f3730403d86.webp" />
          </div>
          <div className={styles.infoBlock}>
            <div className={styles.row1}>
              <span className={styles.prodCategory}>
                Սպասք լվացող մեքենաներ
              </span>
              <div className={styles.shareBtns}>
                <div className={styles.compareHeart}>
                  <a href="#">
                    <CompareSvg />
                  </a>
                  <span />
                  <a href="#">
                    <HeartSvg />
                  </a>
                </div>
                <div className={styles.shareBtn}>
                  <a href="">
                    <ShareSvg />
                  </a>
                </div>
              </div>
            </div>
            <h1 className={styles.prodName}>TOSHIBA DW10F1CIS(W)</h1>
          </div>
        </div>
      </div>
    </div>
  );
}
