import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

export default function Sales() {
  const { sales, currentLanguage } = useSelector(({ main }) => main);
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef();
  const slidesPerView =
    window.innerWidth > 1024 ? "auto" : window.innerWidth > 600 ? 4 : 2.5;
  return (
    <div style={{ backgroundColor: "#f7f7fb", paddingBottom: "1px" }}>
      <div className="wrapper">
        <div className={styles.titleBlock}>
          <h3 className={styles.title}>ԱԿՑԻԱՆԵՐ</h3>
          <a href="https://vlv.am/sales">
            <span>Տեսնել բոլորը</span>
          </a>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: "relative",
          }}
          onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
          onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
        >
          {window.innerWidth > 768 && (
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                style={{
                  opacity: slidePosition.isBeginning ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                style={{
                  opacity: slidePosition.isEnd ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slideNext()}
              >
                <SliderNextArrowSvg />
              </div>
            </>
          )}

          <Swiper
            spaceBetween={20}
            slidesPerView={slidesPerView}
            style={{ paddingBottom: "15px" }}
            onSlideChange={(e) => {
              setSlidePosition({
                isEnd: e.isEnd,
                isBeginning: e.isBeginning,
              });
            }}
            onSlidesLengthChange={(e) => {
              if (slidesPerView === "auto") {
                if (!isNaN(Math.ceil(e.width / 244)))
                  if (Math.ceil(e.width / 244) < e.slides.length) {
                    setSlidePosition({
                      isEnd: false,
                      isBeginning: true,
                    });
                  } else {
                    setSlidePosition({
                      isEnd: true,
                      isBeginning: true,
                    });
                  }
              } else {
                if (slidesPerView < e.slides.length) {
                  setSlidePosition({
                    isEnd: false,
                    isBeginning: true,
                  });
                } else {
                  setSlidePosition({
                    isEnd: true,
                    isBeginning: true,
                  });
                }
              }
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
            centeredSlides={false}
          >
            {!sales.length
              ? Array.from({ length: 7 }).map((_, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    key={index}
                  >
                    <LoadContent containerClassName={styles.loadContent} />
                  </SwiperSlide>
                ))
              : sales.map((item, index) => {
                  let imageUri = item.gallary_images.find(
                    (elm) => elm.lang === currentLanguage
                  ).image;
                  return (
                    <SwiperSlide
                      key={index}
                      style={{ width: "auto", display: "flex" }}
                    >
                      <a
                        href={`https://vlv.am/sale/${item?.id}?lang=${currentLanguage}`}
                      >
                        <img
                          className={styles.saleImg}
                          src={process.env.REACT_APP_STORAGE_URL + imageUri}
                          alt="VLV"
                        />
                      </a>
                    </SwiperSlide>
                  );
                })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
