import React from "react";

export default function EasySvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      x="0px"
      y="0px"
      viewBox="0 0 512 153.8"
    >
      <g>
        <polygon
          fill="#58595B"
          points="152.9,112.1 109.5,112.1 109.5,45.6 152.9,45.6 152.9,54.5 120.1,54.5 120.1,73.9 151.1,73.9    151.1,82.5 120.1,82.5 120.1,103.1 152.9,103.1  "
        />
        <path
          fill="#58595B"
          d="M178.6,85.8h20.7l-10.2-29.1h-0.3L178.6,85.8z M202,94.2h-26.1l-6.2,18h-11l24.5-66.5h11.5l24.7,66.5h-11.2   L202,94.2z"
        />
        <path
          fill="#58595B"
          d="M233.9,93.5c0.6,6.6,7.2,11,16.3,11c8.7,0,14.9-4.4,14.9-10.3c0-5.2-3.7-8.3-12.7-10.3l-8.8-2.1   c-12.5-3-18.3-8.7-18.3-18c0-11.4,10.2-19.2,24.8-19.2c14.5,0,24.3,7.9,24.7,19.2h-10.3c-0.6-6.6-6.3-10.6-14.5-10.6   c-8.3,0-14,4-14,9.9c0,4.6,3.6,7.5,12.4,9.6l7.6,1.8c14,3.2,19.8,8.7,19.8,18.5c0,12.4-10.2,20.2-26.4,20.2   c-15.1,0-25.3-7.6-26-19.6H233.9z"
        />
        <polygon
          fill="#58595B"
          points="303.5,112.1 303.5,84.9 279.5,45.6 291.4,45.6 308.8,74.9 309.1,74.9 326.5,45.6 338.3,45.6    314.1,84.9 314.1,112.1  "
        />
        <path
          fill="#58595B"
          d="M352.4,81.4h16.1c10.6,0,16.8-5.7,16.8-15.1c0-9.4-6.2-15-16.8-15h-16.1V81.4z M370.1,45.6   c13,0,22,8.4,22,20.7c0,12.4-8.9,20.8-22,20.8h-17.7v25.1h-6.5V45.6H370.1z"
        />
        <path
          fill="#58595B"
          d="M406.2,86.3H431l-12.3-32.9h-0.3L406.2,86.3z M433,91.7h-28.7l-7.6,20.4h-6.8l25.6-66.5h6.5l25.4,66.5h-6.8   L433,91.7z"
        />
        <polygon
          fill="#58595B"
          points="465,112.1 465,83.3 440.5,45.6 447.9,45.6 468.1,77.1 468.4,77.1 488.5,45.6 495.9,45.6 471.5,83.3    471.5,112.1  "
        />
        <path
          fill="#A6CE39"
          d="M21.4,29.4l17.2,9.7l17.2,9.6c2.3,1.4,2.3,4.6,0,5.9l-17.2,9.7L21.4,74c-2.3,1.3-5.3-0.4-5.3-3V51.7V32.4   c0-2.1,1.7-3.5,3.6-3.5C20.3,28.9,20.8,29.1,21.4,29.4L21.4,29.4z"
        />
        <path
          fill="#A6CE39"
          d="M62.8,54.6l-17.2,9.7L28.3,74c-2.3,1.3-2.3,4.5,0,5.9l17.3,9.6l17.2,9.7c2.3,1.3,5.2-0.3,5.2-3V77V57.6   C67.9,55,65.1,53.3,62.8,54.6L62.8,54.6z"
        />
        <path
          fill="#A6CE39"
          d="M55.8,99.2l-17.2-9.7l-17.2-9.6c-2.3-1.4-5.3,0.3-5.3,2.8v19.4v19.4c0,1.9,1.7,3.4,3.5,3.4h0.1   c0.5,0,1-0.1,1.7-0.4l17.2-9.7l17.2-9.7C58.1,103.8,58.1,100.5,55.8,99.2L55.8,99.2z"
        />
      </g>
    </svg>
  );
}
