import React, { useState } from "react";
import styles from "./style.module.css";
import MinusSvg from "../assets/MinusSvg";
import PlusSvg from "../assets/PlusSvg";
import HeartSvg from "../../../../../assets/SVG/HeartSvg";
import CompareSvg from "../../../../../assets/SVG/CompareSvg";

export default function Product({ item }) {
  const [showInfo, setShowInfo] = useState();
  return (
    <div className={styles.product}>
      <div className={styles.plusBtn} onClick={() => setShowInfo(!showInfo)}>
        {showInfo ? <MinusSvg /> : <PlusSvg />}
      </div>
      <div
        className={`${styles.infoContainer} ${
          showInfo && styles.showedInfoContainer
        }`}
      >
        <div className={styles.smallImg}>
          <img
            src={
              process.env.REACT_APP_STORAGE_URL +
              item?.product?.gallary_images?.[0]?.thum_image_sourc
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = require("../../../../../assets/defaultImg.png");
            }}
            alt={item.product.brand.name + " " + item?.product?.product_name}
          />
        </div>
        <div className={styles.aboutProduct}>
          <div className={styles.aboutProductInfo}>
            <span className={styles.price}>
              {item.skus?.[0]?.selling_price?.toLocaleString("en-US")} ֏
              {/* item?.product?.online_selling_pric || */}
            </span>
            <span className={styles.category}>Հեռուստացույցներ</span>
            <span className={styles.name}>
              {item.product.brand.name + " " + item?.product?.product_name}
            </span>
            <span className={styles.priceMounth}>3,700 ֏ / Ամիս</span>
          </div>

          <div className={styles.btnsContainer}>
            <HeartSvg />
            <CompareSvg />
          </div>
        </div>
      </div>
      <img
        className={styles.productImg}
        src={
          process.env.REACT_APP_STORAGE_URL +
          item?.product?.thumbnail_image_source
        }
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = require("../../../../../assets/defaultImg.png");
        }}
        alt={item.product.brand.name + " " + item?.product?.product_name}
      />
    </div>
  );
}
