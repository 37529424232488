import React from "react";

export default function HeartStrokeSvg() {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4141 0.399902C19.1009 0.42024 17.8163 0.785244 16.6901 1.45805C15.5639 2.13086 14.636 3.08765 14 4.2318C13.364 3.08765 12.4361 2.13086 11.3099 1.45805C10.1837 0.785244 8.89912 0.42024 7.58592 0.399902C5.49253 0.490464 3.52022 1.4023 2.09988 2.93619C0.679543 4.47008 -0.0733647 6.50136 0.00564568 8.58623C0.00564568 16.4532 12.7825 25.5395 13.3259 25.925L14 26.3999L14.6741 25.925C15.2175 25.5418 27.9944 16.4532 27.9944 8.58623C28.0734 6.50136 27.3205 4.47008 25.9001 2.93619C24.4798 1.4023 22.5075 0.490464 20.4141 0.399902ZM14 23.5411C10.2064 20.7194 2.33804 13.7872 2.33804 8.58623C2.25832 7.117 2.76529 5.67599 3.74839 4.57748C4.73149 3.47897 6.11096 2.81208 7.58592 2.72226C9.06089 2.81208 10.4403 3.47897 11.4234 4.57748C12.4065 5.67599 12.9135 7.117 12.8338 8.58623H15.1662C15.0865 7.117 15.5935 5.67599 16.5766 4.57748C17.5597 3.47897 18.9391 2.81208 20.4141 2.72226C21.889 2.81208 23.2685 3.47897 24.2516 4.57748C25.2347 5.67599 25.7417 7.117 25.662 8.58623C25.662 13.7895 17.7936 20.7194 14 23.5411Z"
        fill="#282828"
      ></path>
    </svg>
  );
}
