import React from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";
import MapMarkerSvg from "./assets/MapMarkerSvg";
import MailSvg from "./assets/MailSvg";
import PhoneSvg from "../../assets/SVG/PhoneSvg";
import FacebookSvg from "../../assets/SVG/FacebookSvg";
import InstagramSvg from "../../assets/SVG/InstagramSvg";
import WhatsappSvg from "../../assets/SVG/WhatsappSvg";
import ViberSvg from "../../assets/SVG/ViberSvg";

export default function Contacts() {
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "Կապ", value: "/contacts", active: true }]}
      />
      <h1>ԿԱՊ</h1>
      <div className={styles.contactsSection}>
        <div className={styles.contactItem}>
          <div className={styles.roundSvgContainer}>
            <MapMarkerSvg width="28px" height="28px" />
          </div>
          <span>Արշակունյաց 69/5</span>
        </div>
        <div className={styles.line} />
        <div className={styles.contactItem}>
          <div className={styles.roundSvgContainer}>
            <MailSvg width="28px" height="28px" />
          </div>
          <span>info@vlv.am</span>
        </div>
        <div className={styles.line} />
        <div className={styles.contactItem}>
          <div className={styles.roundSvgContainer}>
            <PhoneSvg width="28px" height="28px" />
          </div>
          <span>010-34-99-44</span>
        </div>
        <div className={styles.line} />
        <div className={styles.contactItem}>
          <div className={styles.roundSvgContainer}>
            <PhoneSvg width="28px" height="28px" />
          </div>
          <span>010-34-99-44</span>
        </div>
        <div className={styles.line} />
        <div className={styles.contactItem} style={{ flexDirection: "column" }}>
          <span className={styles.contactItemTitle}>Մենք սոց. ցանցերում</span>
          <div className={styles.socIconsContainer}>
            <a href="https://www.facebook.com/people/VLV-Electronics/100064824965577/?mibextid=LQQJ4d">
              <FacebookSvg />
            </a>
            <a href="https://www.instagram.com/vlv_electronics/">
              <InstagramSvg />
            </a>
            <a href="https://wa.me/37491296655">
              <WhatsappSvg />
            </a>
            <a href="viber://chat?number=+37491296655/">
              <ViberSvg />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
