import React, { useState } from "react";
import styles from "../style.module.css";
import CloseEyeSvg from "../../../assets/SVG/CloseEyeSvg";
import EyeSvg from "../../../assets/SVG/EyeSvg";
import { useDispatch } from "react-redux";
import { UserSignUp } from "../../../store/MainSlice";

export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [password2Error, setPassword2Error] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = () => {
    setFirstNameError(!firstName);
    setLastNameError(!lastName);
    setEmailError(!email);
    setPhoneError(!phone);
    setPasswordError(!password);
    setPassword2Error(!password2 || password !== password2);
    if (
      firstName &&
      lastName &&
      email &&
      phone &&
      password &&
      password2 &&
      password === password2
    ) {
      dispatch(
        UserSignUp({ firstName, lastName, email, phone, password, password2 })
      );
    }
  };

  return (
    <>
      <span className={styles.subTitle}>Լրացրեք Ձեր անձնական տվյալները</span>
      <div className={styles.twoInps}>
        <input
          type="text"
          placeholder="Անուն"
          className={`${styles.input} ${firstNameError && styles.inputError}`}
          value={firstName}
          onChange={(e) => setFirstName(e.target?.value)}
        />
        <input
          type="text"
          placeholder="Ազգանուն"
          className={`${styles.input} ${lastNameError && styles.inputError}`}
          value={lastName}
          onChange={(e) => setLastName(e.target?.value)}
        />
      </div>
      <input
        type="email"
        placeholder="Էլ․հասցե"
        className={`${styles.input} ${emailError && styles.inputError}`}
        value={email}
        onChange={(e) => setEmail(e.target?.value)}
      />
      <input
        type="text"
        placeholder="0XXXXXXXX *"
        className={`${styles.input} ${phoneError && styles.inputError}`}
        value={phone}
        onChange={(e) => setPhone(e.target?.value)}
      />

      <div className={styles.passwordContainer}>
        <input
          type={showPass ? "text" : "password"}
          placeholder="Գաղտնաբառ"
          className={`${styles.input} ${passwordError && styles.inputError}`}
          value={password}
          onChange={(e) => setPassword(e.target?.value)}
        />
        <span className={styles.eye} onClick={() => setShowPass(!showPass)}>
          {!showPass ? <CloseEyeSvg /> : <EyeSvg />}
        </span>
      </div>
      <div className={styles.passwordContainer}>
        <input
          type={showPass2 ? "text" : "password"}
          placeholder="Գաղտնաբառ"
          className={`${styles.input} ${password2Error && styles.inputError}`}
          value={password2}
          onChange={(e) => setPassword2(e.target?.value)}
        />
        <span className={styles.eye} onClick={() => setShowPass2(!showPass2)}>
          {!showPass2 ? <CloseEyeSvg /> : <EyeSvg />}
        </span>
      </div>
      <div className={styles.btn} onClick={handleSubmit}>
        <span>Գրանցվել</span>
      </div>
      <div className={styles.line} />
    </>
  );
}
