import React from "react";

export default function VisaSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="15"
      viewBox="0 0 46 15"
      fill="none"
    >
      <path
        d="M20.0413 14.4774H16.4103L18.6796 0.530943H22.311L20.0413 14.4774ZM13.3556 0.530943L9.89401 10.1235L9.48439 8.05781L9.48476 8.05856L8.26299 1.78667C8.26299 1.78667 8.11525 0.530943 6.54057 0.530943H0.817815L0.750664 0.76709C0.750664 0.76709 2.50069 1.1312 4.54879 2.36118L7.7034 14.4778H11.4866L17.2634 0.530943H13.3556ZM41.9153 14.4774H45.2493L42.3424 0.53057H39.4236C38.0758 0.53057 37.7475 1.56992 37.7475 1.56992L32.3321 14.4774H36.1172L36.8741 12.4058H41.49L41.9153 14.4774ZM37.9198 9.54409L39.8276 4.32497L40.9009 9.54409H37.9198ZM32.616 3.88476L33.1342 0.889826C33.1342 0.889826 31.5353 0.281738 29.8684 0.281738C28.0665 0.281738 23.7875 1.06927 23.7875 4.89874C23.7875 8.50176 28.8097 8.54653 28.8097 10.4391C28.8097 12.3316 24.305 11.9925 22.8183 10.7991L22.2785 13.9305C22.2785 13.9305 23.8998 14.7181 26.3769 14.7181C28.8548 14.7181 32.5929 13.4351 32.5929 9.94326C32.5929 6.31711 27.5256 5.97949 27.5256 4.40294C27.526 2.82601 31.0622 3.02858 32.616 3.88476Z"
        fill="#0549AE"
      />
    </svg>
  );
}
