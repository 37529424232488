import React from "react";

export default function CatalogSvg({ fill = "white", ...props }) {
  return (
    <svg
      width="17"
      height="12"
      viewBox="0 0 17 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="16" height="2" fill={fill}></rect>
      <rect y="5" width="11" height="2" fill={fill}></rect>
      <rect y="10" width="17" height="2" fill={fill}></rect>
    </svg>
  );
}
