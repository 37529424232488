import React, { useEffect } from "react";
import styles from "./style.module.css";
import LogoSvg from "../../assets/SVG/LogoSvg";
import { useSelector } from "react-redux";

const Loader = ({ children }) => {
  const pending = useSelector(({ main }) => main.pending);

  useEffect(() => {
    if (pending) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [pending]);

  return (
    <div>
      {pending && (
        <div className={styles.background}>
          <div>
            <LogoSvg />
            <div className={styles.dotsContainer}>
              <span className={`${styles.dot} ${styles.dot1}`}></span>
              <span className={`${styles.dot} ${styles.dot2}`}></span>
              <span className={`${styles.dot} ${styles.dot3}`}></span>
              <span className={`${styles.dot} ${styles.dot4}`}></span>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default Loader;
