import React from "react";
import styles from "./style.module.css";
import { useSelector } from "react-redux";

export default function SearchModal() {
  const { searchInfo, currentLanguage } = useSelector(({ main }) => main);
  if (!Object.keys(searchInfo || {}).length) return null;
  console.log("searchInfo", searchInfo);
  console.log("currentLanguage", searchInfo);

  return (
    <div className={styles.container}>
      {Object.keys(searchInfo?.category || {}).length && (
        <div className={styles.searchedProduct}>
          <div className={styles.searchedProdName}>
            <img
              className={styles.img}
              src={
                process.env.REACT_APP_STORAGE_URL + searchInfo?.category?.icon
              }
              alt={searchInfo?.category?.["name_" + currentLanguage]}
            />
            <span className={styles.name}>
              {searchInfo?.category?.["name_" + currentLanguage]}
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
