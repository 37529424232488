import React from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";

export default function Job() {
  return (
    <div className="wrapper">
      <FromToPage pages={[{ label: "Բլոգ", value: "/job", active: true }]} />
    </div>
  );
}
