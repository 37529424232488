import React, { useState } from "react";
import styles from "../style.module.css";
import EyeSvg from "../../../assets/SVG/EyeSvg";
import CloseEyeSvg from "../../../assets/SVG/CloseEyeSvg";
import { useDispatch } from "react-redux";
import { UserLogin } from "../../../store/MainSlice";
import ArrowSvg from "../assets/ArrowSvg";

export default function ForgotPassword({ setShowLogin }) {
  const [login, setLogin] = useState("");
  const [loginError, setLoginError] = useState(false);

  const dispatch = useDispatch();
  const handleSubmit = () => {
    setLoginError(!login);
  };
  return (
    <>
      <input
        type="email"
        placeholder="Էլ․հասցե"
        className={`${styles.input} ${loginError && styles.inputError}`}
        value={login}
        onChange={(e) => setLogin(e.target.value)}
      />
      <div className={styles.btn} onClick={handleSubmit}>
        <span>Առաջ</span>
        <span className={styles.btnArrow}>
          <ArrowSvg />
        </span>
      </div>
    </>
  );
}
