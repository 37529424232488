import React from "react";

export default function TelCellSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      id="Слой_1"
      x="0px"
      y="0px"
      viewBox="0 0 1920 1080"
      //   style="enable-background:new 0 0 1920 1080;"
    >
      <g>
        <path
          fill="#73E0FE"
          d="M372.1,714.8c13.3-43.5,26.5-86.6,39.5-129.7c1.4-4.7,3.5-6.1,8.3-6c16.3,0.3,32.7,0.3,49,0   c3.8-0.1,5.4,1.1,6.6,4.7c13.6,44.2,27.3,88.3,41,132.5c0.4,1.4,0.9,2.8,1.8,5.1c4.6-16.1,9-31.2,13.4-46.4   c8.9-30.7,17.8-61.3,26.5-92c0.8-2.9,2.1-4,5.2-3.9c20.3,0.1,40.7,0.1,61,0c3.4,0,4.2,0.7,3.1,4.1c-10.4,33.5-20.7,67-30.9,100.6   c-13.3,43.6-26.8,87.1-39.8,130.7c-1.6,5.3-3.9,6.8-9.3,6.7c-19-0.4-38-0.2-57-0.1c-3.6,0-5.1-1.1-6.2-4.6   c-13.2-41-26.5-81.9-39.9-122.9c-0.5-1.6-1.2-3.1-2.1-5.5c-6.7,21-13.2,41.1-19.6,61.2c-7,22-14.2,44-20.9,66.1   c-1.4,4.6-3.5,5.8-8,5.7c-17.3-0.3-34.7-0.2-52,0c-4.3,0.1-6.2-0.9-7.6-5.4c-11.8-38.6-24-77.2-36-115.7   c-12.1-38.7-24.1-77.5-36.2-116.2c-0.4-1.4-1.3-2.7-0.7-4.7c6.1,0,12.2,0,18.3,0c15.7,0,31.3,0.1,47-0.1c3.9-0.1,5.3,1.3,6.3,5   c12.5,42.8,25.2,85.4,37.9,128.2C371,713,371.5,713.7,372.1,714.8z"
        />
        <path
          fill="#FE5B2E"
          d="M1079.3,368.8c-10,0-20-0.1-30,0c-2.4,0-3.8-0.6-5-3c-9.6-19.7-25.9-29.1-47.3-28.9   c-23.3,0.2-41.7,10-50.6,32.2c-9.6,23.8-8.1,47.2,8.7,67.7c20,24.5,62.1,24.7,82.3,0.9c2.1-2.5,4.4-5.3,5.4-8.3   c1.7-5.7,5.3-6.3,10.4-6.2c19,0.3,38,0.2,57,0c4.1,0,4.6,1.2,3.8,4.9c-11.2,52.1-51.1,87.5-104.4,92.1   c-40.6,3.5-76.2-7.7-104.3-38.1c-22.7-24.6-31.9-54.5-31.6-87.5c0.2-27.8,7.3-53.7,24.1-76.3c19.9-26.9,47.1-41.6,79.9-46   c30.4-4.1,59.1,1,85.1,17.7c26.9,17.3,43.9,41.6,50.4,73.1c1.2,5.6,1,5.8-4.9,5.8c-9.7,0-19.3,0-29,0   C1079.3,369,1079.3,368.9,1079.3,368.8z"
        />
        <path
          fill="#73E0FE"
          d="M1277.3,700.3c0-38.5,0.1-77-0.1-115.4c0-4.7,1.3-5.8,5.8-5.8c54.3,0.2,108.6,0.2,162.9,0   c4.5,0,5.9,1.1,5.8,5.8c-0.3,17-0.3,34,0,51c0.1,4.6-1.4,5.5-5.7,5.4c-33.2-0.2-66.3,0-99.5-0.2c-4,0-5.6,0.8-5.4,5.2   c0.4,6,0.3,12,0,18c-0.2,4,1.1,5.1,5,5c24.8-0.2,49.6,0,74.5-0.2c4.5,0,5.9,1.1,5.8,5.7c-0.3,17.2-0.2,34.3,0,51.5   c0.1,4-1.2,5-5.1,5c-25-0.2-50,0-75-0.2c-4.2,0-5.5,1.1-5.3,5.3c0.3,5.8,0.4,11.7,0,17.5c-0.4,4.7,1.5,5.4,5.7,5.4   c32.8-0.2,65.6,0,98.5-0.2c4.9,0,6.5,1,6.4,6.2c-0.4,17-0.3,34,0,51c0.1,4-1.2,5-5.1,5c-54.8-0.1-109.6-0.1-164.4,0   c-4.5,0-5.1-1.7-5-5.5C1277.3,777.3,1277.3,738.8,1277.3,700.3z"
        />
        <path
          fill="#FE5B2E"
          d="M503.9,396.3c0-38.7,0.1-77.3-0.1-116c0-4.1,1-5.3,5.2-5.3c54.3,0.1,108.6,0.2,163,0c4.5,0,5.5,1.2,5.4,5.6   c-0.3,17.2-0.3,34.3,0,51.5c0.1,4.3-1.3,5.2-5.3,5.2c-32.8-0.2-65.7,0-98.5-0.2c-4.6,0-5.7,1.3-5.4,5.6c0.4,5.6,0.5,11.4,0,17   c-0.5,5.1,1.5,5.9,6.1,5.8c24-0.2,48,0,72-0.2c4,0,5.4,0.7,5.3,5c-0.3,17.7-0.2,35.3,0,53c0,3.3-0.9,4.1-4.2,4.1   c-24.7-0.1-49.3,0-74-0.2c-4.2,0-5.4,1.1-5.2,5.2c0.4,6,0.3,12,0,18c-0.2,3.9,0.9,5,4.9,5c32.8-0.2,65.7,0,98.5-0.2   c4.3,0,5.9,0.8,5.8,5.6c-0.3,17.2-0.2,34.3-0.1,51.5c0,3.7-0.6,5.1-4.8,5.1c-54.7-0.2-109.3-0.2-164,0c-4.2,0-4.8-1.4-4.8-5.1   C504,473.6,503.9,434.9,503.9,396.3z"
        />
        <path
          fill="#FE5B2E"
          d="M1132.9,396.3c0-38.7,0.1-77.3-0.1-116c0-4.2,1.1-5.3,5.2-5.2c54.3,0.1,108.7,0.1,163,0c3.7,0,5.2,0.7,5.1,4.8   c-0.2,17.3-0.2,34.7,0,52c0.1,4.1-1,5.3-5.2,5.3c-32.8-0.2-65.7,0-98.5-0.2c-4.5,0-5.8,1.2-5.5,5.5c0.4,5.8,0.5,11.7,0,17.5   c-0.4,4.7,1.5,5.4,5.6,5.3c24.3-0.2,48.7,0,73-0.2c3.7,0,5.1,0.7,5,4.8c-0.3,17.7-0.2,35.3,0,53c0,3.6-1.1,4.4-4.5,4.3   c-24.7-0.1-49.3,0-74-0.2c-4.3,0-5.4,1.3-5.1,5.3c0.3,6.1,0.3,12.3,0,18.5c-0.2,3.6,1.1,4.4,4.5,4.4c33-0.1,66,0,99-0.2   c4.7,0,5.7,1.4,5.7,5.8c-0.3,17.3-0.2,34.7,0,52c0,3.6-1.1,4.4-4.5,4.4c-54.7-0.1-109.3-0.1-164,0c-4.2,0-4.8-1.4-4.8-5.1   C1132.9,473.6,1132.9,434.9,1132.9,396.3z"
        />
        <path
          fill="#73E0FE"
          d="M1583.4,579.2c34,0,68,0.1,101.9-0.1c4.2,0,5.3,1.1,5.3,5.3c-0.2,17.3-0.2,34.6,0,52c0.1,4.1-1.3,5-5.1,4.9   c-21.3-0.2-42.6,0.1-64-0.2c-4.7-0.1-6.2,1-6.1,6c0.2,56.1,0.1,112.3,0.2,168.4c0,4.4-1,6-5.7,5.9c-17.3-0.3-34.6-0.1-52-0.1   c-6.5,0-6.5,0-6.5-6.3c0-55.8-0.1-111.6,0.1-167.4c0-4.8-0.8-6.5-6.2-6.4c-21.1,0.4-42.3,0-63.5,0.3c-4.8,0.1-5.7-1.5-5.7-5.9   c0.2-16.8,0.2-33.6,0-50.5c-0.1-4.5,1-5.9,5.7-5.9C1515.8,579.3,1549.6,579.2,1583.4,579.2z"
        />
        <path
          fill="#FE5B2E"
          d="M1335.9,396c0-38.5,0.1-77-0.1-115.4c0-4.4,1-5.6,5.5-5.5c18,0.3,36,0.3,54,0c4.2-0.1,5.2,1.2,5.2,5.3   c-0.1,56.5,0,112.9-0.2,169.4c0,4.7,1.4,5.6,5.8,5.6c30.2-0.2,60.3,0,90.5-0.2c3.9,0,5,1,5,5c-0.2,17.7-0.2,35.3,0,53   c0,3.2-0.8,4.2-4.1,4.2c-52.5-0.1-105-0.1-157.4,0c-4.2,0-3.9-2.1-3.9-4.9C1335.9,473.6,1335.9,434.8,1335.9,396z"
        />
        <path
          fill="#73E0FE"
          d="M894.6,700.2c0-38.5,0.1-77-0.1-115.4c0-4.2,0.8-5.7,5.4-5.7c17.5,0.3,35,0.1,52.5,0.1c6.3,0,6.3,0,6.3,6.6   c0,55.6,0,111.3,0,166.9c0,6.5,0,6.5,6.4,6.5c30.5,0,61,0.1,91.5-0.1c3.8,0,5.1,0.9,5.1,5c-0.2,17.5-0.2,35,0,52.5   c0,3.8-1.1,4.7-4.8,4.7c-52.5-0.1-105-0.1-157.4,0c-4.1,0-4.9-1.4-4.9-5.1C894.6,777.5,894.6,738.9,894.6,700.2z"
        />
        <path
          fill="#FE5B2E"
          d="M707.4,396.2c0-38.6,0.1-77.3-0.1-115.9c0-4,0.8-5.4,5.1-5.3c17.8,0.3,35.6,0.3,53.5,0   c4.4-0.1,5.8,0.9,5.8,5.6c-0.2,56.3,0,112.6-0.2,168.9c0,5,1.4,5.9,6.1,5.8c30.1-0.2,60.3,0,90.4-0.2c3.6,0,4.8,0.8,4.7,4.6   c-0.2,17.7-0.2,35.3,0,53c0,3.2-0.4,4.6-4.2,4.6c-52.3-0.1-104.6-0.1-156.9,0c-3.8,0-4.2-1.5-4.2-4.7   C707.4,473.8,707.4,435,707.4,396.2z"
        />
        <path
          fill="#FE5B2E"
          d="M1525.4,396.1c0-38.6,0.1-77.3-0.1-115.9c0-4.2,1.1-5.2,5.2-5.2c17.8,0.2,35.6,0.2,53.5,0   c4.3-0.1,5.5,0.8,5.5,5.4c-0.2,56.5-0.1,112.9-0.2,169.4c0,4.8,1.4,5.6,5.8,5.6c30.1-0.2,60.3,0,90.4-0.2c4.3,0,5.2,1.2,5.1,5.3   c-0.2,17.5-0.2,35,0,52.5c0,3.4-0.8,4.5-4.4,4.5c-52.1-0.1-104.3-0.1-156.4,0c-4,0-4.5-1.3-4.5-4.8   C1525.5,473.7,1525.4,434.9,1525.4,396.1z"
        />
        <path
          fill="#73E0FE"
          d="M1086.3,700c0-38.3,0.1-76.6-0.1-115c0-4.3,0.8-6,5.7-6c17.5,0.3,35,0.3,52.5,0c4.6-0.1,5.4,1.4,5.4,5.7   c-0.1,56.2,0,112.3-0.2,168.5c0,4.8,1.2,6.1,6,6.1c30.5-0.2,61,0,91.5-0.2c4.2,0,5.8,0.8,5.7,5.4c-0.3,17.3-0.2,34.7,0,52   c0,3.8-1.1,4.8-4.8,4.8c-52.3-0.1-104.6-0.1-157,0c-4.3,0-4.8-1.7-4.8-5.3C1086.3,777.3,1086.3,738.7,1086.3,700z"
        />
        <path
          fill="#FE5B2E"
          d="M399.7,396.5c0,38.6-0.1,77.3,0.1,115.9c0,4-1.1,4.9-5,4.9c-18-0.2-36-0.2-54,0c-4.1,0.1-5.3-0.9-5.3-5.2   c0.1-56.3,0-112.6,0.2-168.9c0-4.9-0.9-6.4-6.1-6.3c-21.5,0.3-43,0-64.5,0.2c-4.1,0-4.9-1.2-4.9-5.1c0.2-17.5,0.2-35,0-52.5   c0-3.8,1.1-4.7,4.7-4.7c43.3,0.1,86.6,0.1,129.9,0c4.2,0,4.8,1.4,4.8,5.1C399.6,318.9,399.7,357.7,399.7,396.5z"
        />
        <path
          fill="#FE5B2E"
          d="M451.8,275.3c9.3,0,18.6,0.1,28-0.1c3-0.1,4,0.6,4,3.8c-0.2,18.1-0.1,36.3,0,54.4c0,2.8-0.8,3.7-3.7,3.7   c-19-0.1-37.9-0.1-56.9,0c-2.8,0-3.7-0.8-3.7-3.7c0.1-18.3,0.1-36.6,0-54.9c0-2.6,0.7-3.4,3.4-3.4   C432.5,275.4,442.2,275.3,451.8,275.3z"
        />
        <path
          fill="#73E0FE"
          d="M870.5,816.3c-30.4-77.8-60.6-155.6-90.8-233.4c-1.1-2.8-2.5-3.7-5.4-3.7c-11.3,0.1-22.7,0.1-34,0.1   c-11.2,0-22.3,0.2-33.5-0.1c-4.2-0.1-5.8,1.5-7.1,5.1c-4.8,13.1-9.8,26.2-14.8,39.2c-24.6,64.5-49.3,128.9-74,193.4   c-1.2,3.2-1.2,4.6,2.9,4.5c18.5-0.2,37-0.2,55.5,0c3.6,0,4.9-1.3,6-4.4c3.9-11,8.3-21.8,12.2-32.7c1.1-3,2.6-4,5.8-3.9   c30.7,0.1,61.3,0.1,92,0c3.5,0,5,1.2,6.1,4.3c4,10.9,8.5,21.7,12.4,32.6c1.2,3.3,2.9,4.2,6.2,4.1c19-0.2,38-0.2,57,0   C871.5,821.3,872.1,820.5,870.5,816.3z M762.2,720.4c-16.6-0.2-33.2-0.1-50.6-0.1c9.1-24,17.9-47.3,27.2-72   c3.5,9,6.4,16.6,9.4,24.3c5.6,14.6,11.2,29.2,16.9,43.7C766.3,719.4,765.8,720.4,762.2,720.4z"
        />
      </g>
    </svg>
  );
}
