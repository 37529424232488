import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";
import { Autoplay } from "swiper/modules";

export default function FeatureCategories() {
  const { featureCategories, currentLanguage } = useSelector(
    ({ main }) => main
  );
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef();
  return (
    <div className="wrapper">
      <h3 className="title">ՀԱՅՏՆԻ ԲԱԺԻՆՆԵՐ</h3>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          position: "relative",
        }}
        onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
        onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
      >
        {window.innerWidth > 768 && (
          <>
            <div
              className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
              style={{
                opacity: slidePosition.isBeginning ? "0.35" : "1",
                display: showBtns ? "flex" : "none",
              }}
              onClick={() => swiperRef.current?.slidePrev()}
            >
              <SliderNextArrowSvg />
            </div>
            <div
              className={`${styles.slideBtn} ${styles.slideNextArrow}`}
              style={{
                opacity: slidePosition.isEnd ? "0.35" : "1",
                display: showBtns ? "flex" : "none",
              }}
              onClick={() => swiperRef.current?.slideNext()}
            >
              <SliderNextArrowSvg />
            </div>
          </>
        )}
        <Swiper
          modules={[Autoplay]}
          spaceBetween={window.innerWidth > 768 ? 20 : 0}
          slidesPerView={"auto"}
          style={{ paddingBottom: "15px" }}
          onSlideChange={(e) => {
            setSlidePosition({
              isEnd: e.isEnd,
              isBeginning: e.isBeginning,
            });
          }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {!featureCategories?.length
            ? Array.from({ length: 11 }).map((_, index) => (
                <SwiperSlide
                  style={{ width: "auto", display: "flex" }}
                  key={index}
                >
                  <LoadContent containerClassName={styles.loadContent} />
                </SwiperSlide>
              ))
            : featureCategories.map((item, index) => (
                <SwiperSlide
                  style={{ width: "auto", display: "flex" }}
                  className={styles.slideItem}
                  key={index}
                >
                  <a
                    href={`https://vlv.am/category/${item?.slug}?item=category&?lang=${currentLanguage}`}
                  >
                    <img
                      className={styles.slideItemImg}
                      src={
                        process.env.REACT_APP_STORAGE_URL +
                        item.category_image.image
                      }
                      alt={item.name_en}
                    />
                    <span className={styles.slideItemTitle}>
                      {item["name_" + currentLanguage]}
                    </span>
                  </a>
                </SwiperSlide>
              ))}
        </Swiper>
      </div>
    </div>
  );
}
