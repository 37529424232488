import React from "react";
import FromToPage from "../../components/FromToPage";
import styles from "./style.module.css";

export default function Contacts() {
  return (
    <div className="wrapper">
      <FromToPage
        pages={[{ label: "Աշխատանք", value: "/job", active: true }]}
      />
    </div>
  );
}
