import React from "react";

export default function Paralax4Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="271"
      height="250"
      viewBox="0 0 271 250"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_iif_1172_41449)">
        <path
          d="M38.0461 66.1849C30.1547 52.5166 40.0189 35.4311 55.8017 35.4311L214.92 35.4311C230.703 35.4311 240.567 52.5165 232.676 66.1848L153.117 203.985C145.225 217.654 125.497 217.654 117.605 203.985L38.0461 66.1849Z"
          fill="#E31335"
        />
      </g>
      <defs>
        <filter
          id="filter0_iif_1172_41449"
          x="0.268677"
          y="0.431152"
          width="270.184"
          height="248.805"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="16" dy="-6" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1172_41449"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-11" dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_1172_41449"
            result="effect2_innerShadow_1172_41449"
          />
          <feGaussianBlur
            stdDeviation="17.5"
            result="effect3_foregroundBlur_1172_41449"
          />
        </filter>
      </defs>
    </svg>
  );
}
