import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import { SwiperSlide, Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import { useSelector } from "react-redux";
import LoadContent from "../../../../components/LoadContent";
import ProductCard from "../../../../components/ProductCard/index";
import SliderNextArrowSvg from "../../../../assets/SVG/SliderNextArrowSvg";

export default function TopPicksProduct() {
  const { topPicksProduct } = useSelector(({ main }) => main);
  const [showBtns, setShowBtns] = useState(false);
  const [slidePosition, setSlidePosition] = useState({
    isBeginning: true,
    isEnd: false,
  });

  const swiperRef = useRef();
  const slidesPerView =
    window.innerWidth > 992
      ? "auto"
      : window.innerWidth > 768
      ? 4
      : window.innerWidth > 480
      ? 3
      : 2;
  return (
    <div className="wrapper productCard">
      <div className={styles.container}>
        <h3 className={styles.title}>ՇԱՏ ԴԻՏՎԱԾ ԱՊՐԱՆՔՆԵՐ</h3>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            position: "relative",
            marginBottom: "15px",
          }}
          onMouseMove={() => window.innerWidth > 768 && setShowBtns(true)}
          onMouseOut={() => window.innerWidth > 768 && setShowBtns(false)}
        >
          {window.innerWidth > 768 && (
            <>
              <div
                className={`${styles.slideBtn} ${styles.slidePrevArrow}`}
                style={{
                  opacity: slidePosition.isBeginning ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <SliderNextArrowSvg />
              </div>
              <div
                className={`${styles.slideBtn} ${styles.slideNextArrow}`}
                style={{
                  opacity: slidePosition.isEnd ? "0.35" : "1",
                  display: showBtns ? "flex" : "none",
                }}
                onClick={() => swiperRef.current?.slideNext()}
              >
                <SliderNextArrowSvg />
              </div>
            </>
          )}
          <Swiper
            spaceBetween={window.innerWidth > 420 ? 26 : 10}
            slidesPerView={slidesPerView}
            onSlideChange={(e) => {
              setSlidePosition({
                isEnd: e.isEnd,
                isBeginning: e.isBeginning,
              });
            }}
            onSlidesLengthChange={(e) => {
              if (slidesPerView === "auto") {
                if (!isNaN(Math.ceil(e.width / 222)))
                  if (Math.ceil(e.width / 222) < e.slides.length) {
                    setSlidePosition({
                      isEnd: false,
                      isBeginning: true,
                    });
                  } else {
                    setSlidePosition({
                      isEnd: true,
                      isBeginning: true,
                    });
                  }
              } else {
                if (slidesPerView < e.slides.length) {
                  setSlidePosition({
                    isEnd: false,
                    isBeginning: true,
                  });
                } else {
                  setSlidePosition({
                    isEnd: true,
                    isBeginning: true,
                  });
                }
              }
            }}
            onBeforeInit={(swiper) => {
              swiperRef.current = swiper;
            }}
          >
            {!topPicksProduct?.length
              ? Array.from({ length: 7 }).map((_, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    key={index}
                  >
                    <LoadContent width="220px" height="230px" />
                  </SwiperSlide>
                ))
              : topPicksProduct.map((item, index) => (
                  <SwiperSlide
                    style={{ width: "auto", display: "flex" }}
                    key={index}
                    className={styles.slideItem}
                  >
                    <ProductCard product={item} />
                  </SwiperSlide>
                ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
