import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "./request";

const initialState = {
  pending: false,
  token: null,
  isAuthed: false,
  currentLanguage: "hy",
  currentCurrency: "amd",
  headerCategorys: [],
  haederSliders: [],
  sales: [],
  featureCategories: [],
  bannerSliders: [],
  workInformations: [],
  bestDealProduct: [],
  topRatingProduct: [],
  topPicksProduct: [],
  blogs: [],
  brands: [],
  footerBaner: null,
  haveMainInfo: false,
  menuData: [],
  showLoginModal: false,
  treeInOne: null,
  specialOffer: null,
  headerBaners: null,
  favoritesCount: 0,
  comparesCount: 0,
  shopCartCount: {
    count: 0,
    price: 0,
  },
  shopCartPositions: null,
  shopCartProductAnimation: null,
  compareProductAnimation: null,
  heartProductAnimation: null,
  searchInfo: null,
};
// let process.env.REACT_APP_TOKEN;
// let CSRF_TOKEN;
export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setToken: (store, action) => {
      return {
        ...store,
        token: action.payload,
      };
    },
    setPending: (store, action) => {
      return {
        ...store,
        pending: action.payload,
      };
    },
    setCurrentLanguage: (store, action) => {
      return {
        ...store,
        currentLanguage: action.payload,
      };
    },
    setCurrentCurrency: (store, action) => {
      return {
        ...store,
        currentCurrency: action.payload,
      };
    },
    setHeaderCategorys: (store, action) => {
      return {
        ...store,
        headerCategorys: action.payload,
      };
    },
    setHeaderSliders: (store, action) => {
      return {
        ...store,
        headerSliders: action.payload,
      };
    },
    setHeaderBaners: (store, action) => {
      return {
        ...store,
        headerBaners: action.payload,
      };
    },
    setSales: (store, action) => {
      return {
        ...store,
        sales: action.payload,
      };
    },
    setFeatureCategories: (store, action) => {
      return {
        ...store,
        featureCategories: action.payload,
      };
    },
    setBannerSliders: (store, action) => {
      return {
        ...store,
        bannerSliders: action.payload,
      };
    },

    setWorkInformations: (store, action) => {
      return {
        ...store,
        workInformations: action.payload,
      };
    },
    setBestDealProduct: (store, action) => {
      return {
        ...store,
        bestDealProduct: action.payload,
      };
    },
    setTopRatingProduct: (store, action) => {
      return {
        ...store,
        topRatingProduct: action.payload,
      };
    },
    setTopPicksProduct: (store, action) => {
      return {
        ...store,
        topPicksProduct: action.payload,
      };
    },

    setBlogs: (store, action) => {
      return {
        ...store,
        blogs: action.payload,
      };
    },
    setBrands: (store, action) => {
      return {
        ...store,
        brands: action.payload,
      };
    },
    setFooterBaner: (store, action) => {
      return {
        ...store,
        footerBaner: action.payload,
      };
    },
    setHaveMainInfo: (store, action) => {
      return {
        ...store,
        haveMainInfo: action.payload,
      };
    },
    setMenuData: (store, action) => {
      return {
        ...store,
        menuData: action.payload,
      };
    },
    setShowLoginModal: (store, action) => {
      return {
        ...store,
        showLoginModal: action.payload,
      };
    },
    setTreeInOne: (store, action) => {
      return {
        ...store,
        treeInOne: action.payload,
      };
    },
    setSpecialOffer: (store, action) => {
      return {
        ...store,
        specialOffer: action.payload,
      };
    },
    setIsAuthed: (store, action) => {
      return {
        ...store,
        isAuthed: action.payload,
      };
    },
    addFavoritesCount: (store, action) => {
      return {
        ...store,
        favoritesCount: store.favoritesCount + 1,
      };
    },
    removeFavoritesCount: (store, action) => {
      return {
        ...store,
        favoritesCount: store.favoritesCount - 1,
      };
    },
    addComparesCount: (store, action) => {
      return {
        ...store,
        comparesCount: store.comparesCount + 1,
      };
    },
    removeComparesCount: (store, action) => {
      return {
        ...store,
        comparesCount: store.comparesCount - 1,
      };
    },
    addShopCartCount: (store, action) => {
      return {
        ...store,
        shopCartCount: {
          count: store.shopCartCount.count + 1,
          price: store.shopCartCount.price + action.payload,
        },
      };
    },
    setShopCartPositions: (store, action) => {
      return {
        ...store,
        shopCartPositions: action.payload,
      };
    },
    setShopCartProductAnimation: (store, action) => {
      return {
        ...store,
        shopCartProductAnimation: action.payload,
      };
    },
    setCompareProductAnimation: (store, action) => {
      return {
        ...store,
        compareProductAnimation: action.payload,
      };
    },
    setHeartProductAnimation: (store, action) => {
      return {
        ...store,
        heartProductAnimation: action.payload,
      };
    },
    setSearchInfo: (store, action) => {
      return {
        ...store,
        searchInfo: action.payload,
      };
    },
  },
});

// export const getToken = () => (dispatch) => {
//   // const token = localStorage.getItem("token");
//   const token = "quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3";

//   // if (token) {
//   dispatch(setToken(token));
//   process.env.REACT_APP_TOKEN = token;
//   return;
//   // }

//   // axiosInstance
//   //   .post("/login", {
//   //     login: process.env.REACT_APP_LOGIN,
//   //     password: process.env.REACT_APP_PASSWORD,
//   //     id_front: "111",
//   //   })
//   //   .then((response) => {
//   //     localStorage.setItem("token", response.data.Token);
//   //     dispatch(setToken(response.data.Token));
//   //   })
//   //   .catch((err) => {
//   //     console.warn("Error: getToken", err);
//   //   });
// };
// export const getCsrfToken = () => async (dispatch) => {
//   const csrfToken = localStorage.getItem("csrf_token2");
//   if (csrfToken) {
//     CSRF_TOKEN = csrfToken;
//     return;
//   }
//   axiosInstance
//     .get("get-the-token", {
//       params: {
//         login: process.env.REACT_APP_LOGIN,
//         password: process.env.REACT_APP_PASSWORD,
//         token: process.env.REACT_APP_TOKEN,
//       },
//     })
//     .then((response) => {
//       localStorage.setItem("token", response.data.token);
//       CSRF_TOKEN = response.data.token;
//       dispatch(setToken(response.data.token));
//     })
//     .catch((err) => {
//       console.warn("Error: getCsrfToken", err);
//     });
// };
export const getMainInfo = () => (dispatch) => {
  // dispatch(setPending(true));
  // if (!process.env.REACT_APP_TOKEN) process.env.REACT_APP_TOKEN = localStorage.getItem("token");
  // if (process.env.REACT_APP_TOKEN) {
  Promise.all([
    dispatch(getHaedaerSliders()),
    dispatch(getHaedaerBaners()),
    dispatch(getWorkInformations()),
  ]).finally(() => {
    console.log("finally");
    // dispatch(setPending(false));
    Promise.all([
      dispatch(getFeatureCategories()),
      dispatch(getSpecialOffer()),
      dispatch(getSales()),
      dispatch(getBestDealProduct()),
      dispatch(getBannerSliders()),
      dispatch(getTopRatingProduct()),
      dispatch(getThreeInOne()),
      dispatch(getTopPicksProduct()),
      dispatch(getBlog()),
      dispatch(getBrand()),
      dispatch(getFooterBaner()),
    ]).finally(() => {
      dispatch(setHaveMainInfo(true));
    });
  });
  // }
};

export const UserLogin =
  ({ login, password }) =>
  (dispatch) => {
    axiosInstance
      .post("/user/login", {
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
        token: process.env.REACT_APP_TOKEN,
        username: login,
        user_password: password,
      })
      .then((response) => {
        console.log("Login response", response.data);
        if (response.data.code === 3) {
          dispatch(setIsAuthed(true));
          dispatch(setShowLoginModal(false));
        }
      })
      .catch((err) => {
        console.warn("Error: Login", err);
      });
  };
export const UserSignUp = (data) => (dispatch) => {
  axiosInstance
    .post("/user/register", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
      first_name: data?.firstName,
      last_name: data?.lastName,
      email: data.email,
      phone: data?.phone,
      user_password: data?.password,
      password_confirmation: data?.password2,
    })
    .then((response) => {
      console.log("SignUp response", response.data);
      if (response.data.code === 3) {
        dispatch(setIsAuthed(true));
        dispatch(setShowLoginModal(false));
      }
    })
    .catch((err) => {
      console.warn("Error: SignUp", err);
    });
};

export const getHaedaerCategorys = () => async (dispatch) => {
  await axiosInstance
    .post("/header-category", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(
        setHeaderCategorys([...response.data.Category, ...response.data.Brand])
      );
    })
    .catch((err) => {
      console.warn("Error: getHaedaerCategorys", err);
    });
};
export const getMenuData = () => async (dispatch) => {
  await axiosInstance
    .post("/menu-select", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setMenuData(response.data));
    })
    .catch((err) => {
      console.warn("Error: getMenuData", err);
    });
};
export const getHaedaerSliders = () => async (dispatch) => {
  await axiosInstance
    .post("/header-slider", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setHeaderSliders(response?.data?.Sliders));
    })
    .catch((err) => {
      console.warn("Error: getHaedaerSliders", err);
    });
};
export const getHaedaerBaners = () => async (dispatch) => {
  await axiosInstance
    .post("/header-baners", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setHeaderBaners(response?.data));
    })
    .catch((err) => {
      console.warn("Error: getHaedaerSliders", err);
    });
};
export const getWorkInformations = () => async (dispatch) => {
  await axiosInstance
    .post("/work-informations", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setWorkInformations(response.data.workInformations));
    })
    .catch((err) => {
      console.warn("Error: getWorkInformations", err);
    });
};
export const getFeatureCategories = () => async (dispatch) => {
  await axiosInstance
    .post("/feature-categories-cat", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setFeatureCategories(response.data.featureCategoriesCat));
    })
    .catch((err) => {
      console.warn("Error: getFeatureCategories", err);
    });
};
export const getSales = () => async (dispatch) => {
  await axiosInstance
    .post("/sales", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setSales(response.data?.sales));
    })
    .catch((err) => {
      console.warn("Error: getSales", err);
    });
};
export const getBannerSliders = () => async (dispatch) => {
  await axiosInstance
    .post("/baner-slider", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      // console.log("getBannerSliders", response?.data);
      dispatch(setBannerSliders(response?.data?.banerSlider));
    })
    .catch((err) => {
      console.warn("Error: getBannerSliders", err);
    });
};
export const getBestDealProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/best-deal-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setBestDealProduct(response.data.bestDealProduct));
    })
    .catch((err) => {
      console.warn("Error: getBestDealProduct", err);
    });
};
export const getTopRatingProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/top-rating-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setTopRatingProduct(response.data?.topRatingProduct));
    })
    .catch((err) => {
      console.warn("Error: getTopRatingProduct", err);
    });
};
export const getThreeInOne = () => async (dispatch) => {
  await axiosInstance
    .post("/three-in-one", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setTreeInOne(response.data));
      // console.log("getThreeInOne", response.data);
    })
    .catch((err) => {
      console.warn("Error: getThreeInOne", err);
    });
};
export const getTopPicksProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/top-picks-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setTopPicksProduct(response.data?.topPicksProduct));
      // console.log("getTopPicksProduct", response.data);
    })
    .catch((err) => {
      console.warn("Error: getTopPicksProduct", err);
    });
};
export const getHomeProduct = () => async (dispatch) => {
  await axiosInstance
    .post("/home-product", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      // console.log("getHomeProduct", response.data);
    })
    .catch((err) => {
      console.warn("Error: getHomeProduct", err);
    });
};
export const getBlog = () => async (dispatch) => {
  await axiosInstance
    .post("/blog", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setBlogs(response.data?.Blog));
    })
    .catch((err) => {
      console.warn("Error: getBlog", err);
    });
};
export const getBrand = () => async (dispatch) => {
  await axiosInstance
    .post("/brand", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setBrands(response.data?.Brand));
    })
    .catch((err) => {
      console.warn("Error: getBrand", err);
    });
};
export const getFooterBaner = () => async (dispatch) => {
  await axiosInstance
    .post("/footer-baner", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      dispatch(setFooterBaner(response.data.footerBaner));
    })
    .catch((err) => {
      console.warn("Error: getFooterBaner", err);
    });
};
export const getSpecialOffer = () => async (dispatch) => {
  await axiosInstance
    .post("/flash-deals", {
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      token: process.env.REACT_APP_TOKEN,
    })
    .then((response) => {
      // console.log("response.data", response.data);
      dispatch(setSpecialOffer(response.data));
    })
    .catch((err) => {
      console.warn("Error: getSpecialOffer", err);
    });
};
export const addCompare = (data) => (dispatch) => {
  console.log("addCompare data", {
    login: process.env.REACT_APP_LOGIN,
    password: process.env.REACT_APP_PASSWORD,
    token: process.env.REACT_APP_TOKEN,
    product_type: "product",
    ...data,
  });
  axiosInstance
    .post("/compare", {
      // token: process.env.REACT_APP_TOKEN,
      // product_type: "product",
      // ...data,
      login: "VlvShopA",
      password: "oL0rG6vQ6k",
      token: "quHRhfW9R7SbNFUCEBEBmzCRbIa5Z3",
      product_sku_id: 7429,
      data_type: 1,
      product_type: "product",
    })
    .then((response) => {
      console.log("response.data", response.data);
    })
    .catch((err) => {
      console.warn("Error: addCompare", err);
    });
};
export const addWishList = (date) => (dispatch) => {
  axiosInstance
    .post("/wishlist/store", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      type: "product",
      seller_id: 1,
      shipping_method_id: 0,
      ...date,
    })
    .then((response) => {
      if (!response.data.error) {
        console.log("wishlist added");
      } else {
        console.log("wishlist error", response.data);
      }
    })
    .catch((err) => {
      console.warn("Error: addWishList", err);
    });
};
export const addCardStore = (date) => (dispatch) => {
  axiosInstance
    .post(
      process.env.REACT_APP_SERVER_URL + "cart/store",
      {
        token: process.env.REACT_APP_TOKEN,
        login: process.env.REACT_APP_LOGIN,
        password: process.env.REACT_APP_PASSWORD,
        ...date,
      },
      {
        headers: {
          common: {
            "X-Requested-With": "XMLHttpRequest",
            withCredentials: true,
          },
        },
      }
    )
    .then((response) => {
      console.log("response.data", response.data);
    })
    .catch((err) => {
      console.warn("Error: addCardStore", err);
    });
};
export const getSearchInfo = (keyword) => (dispatch) => {
  axiosInstance
    .post("get-search-info", {
      token: process.env.REACT_APP_TOKEN,
      login: process.env.REACT_APP_LOGIN,
      password: process.env.REACT_APP_PASSWORD,
      keyword: keyword,
    })
    .then((response) => {
      dispatch(setSearchInfo(response.data.data));
    })
    .catch((err) => {
      console.warn("Error: addCardStore", err);
    });
};

export const {
  setToken,
  setPending,
  setCurrentCurrency,
  setCurrentLanguage,
  setHeaderCategorys,
  setHeaderSliders,
  setHeaderBaners,
  setSales,
  setFeatureCategories,
  setBannerSliders,
  setWorkInformations,
  setBestDealProduct,
  setTopRatingProduct,
  setTopPicksProduct,
  setBlogs,
  setBrands,
  setFooterBaner,
  setHaveMainInfo,
  setMenuData,
  setShowLoginModal,
  setTreeInOne,
  setSpecialOffer,
  setIsAuthed,
  addFavoritesCount,
  removeFavoritesCount,
  addComparesCount,
  removeComparesCount,
  addShopCartCount,
  setShopCartPositions,
  setShopCartProductAnimation,
  setCompareProductAnimation,
  setHeartProductAnimation,
  setSearchInfo,
} = mainSlice.actions;
export default mainSlice.reducer;
