import React from "react";

export default function CloseEyeSvg() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 3.62499C11.07 3.62499 12.75 5.30499 12.75 7.37499C12.75 7.75749 12.675 8.12499 12.57 8.46999L14.865 10.765C15.9075 9.84249 16.7325 8.68749 17.25 7.36749C15.9525 4.08249 12.75 1.74999 9 1.74999C8.0475 1.74999 7.1325 1.89999 6.27 2.17749L7.8975 3.80499C8.25 3.69999 8.6175 3.62499 9 3.62499ZM2.0325 1.11999C1.74 1.41249 1.74 1.88499 2.0325 2.17749L3.51 3.65499C2.295 4.62249 1.3275 5.89749 0.75 7.37499C2.0475 10.6675 5.25 13 9 13C10.14 13 11.2275 12.775 12.2325 12.385L14.2725 14.425C14.565 14.7175 15.0375 14.7175 15.33 14.425C15.6225 14.1325 15.6225 13.66 15.33 13.3675L3.0975 1.11999C2.805 0.827495 2.325 0.827495 2.0325 1.11999ZM9 11.125C6.93 11.125 5.25 9.44499 5.25 7.37499C5.25 6.79749 5.385 6.24999 5.6175 5.76999L6.795 6.94749C6.7725 7.08249 6.75 7.22499 6.75 7.37499C6.75 8.61999 7.755 9.62499 9 9.62499C9.15 9.62499 9.285 9.60249 9.4275 9.57249L10.605 10.75C10.1175 10.99 9.5775 11.125 9 11.125ZM11.2275 7.12749C11.115 6.07749 10.29 5.25999 9.2475 5.14749L11.2275 7.12749Z"
        fill="#717171"
        fill-opacity="0.2"
      ></path>
    </svg>
  );
}
