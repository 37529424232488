import React from "react";
import styles from "./style.module.css";
import HomeSvg from "./assets/HomeSvg";

import ShopCartSvg from "../../assets/SVG/ShopCartSvg";
import CompareSvg from "../../assets/SVG/CompareSvg";
import UserSvg from "./assets/UserSvg";
import HeartStrokeSvg from "./assets/HeartStrokeSvg";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../store/MainSlice";

export default function MobileFooter() {
  const {
    isAuthed,
    favoritesCount,
    comparesCount,
    shopCartCount,
    currentLanguage,
  } = useSelector(({ main }) => main);
  const dispatch = useDispatch();
  return (
    <div className={styles.container}>
      <div>
        <HomeSvg />
      </div>
      <div>
        {favoritesCount > 0 && <span>{favoritesCount}</span>}
        <HeartStrokeSvg />
      </div>
      <div>
        {shopCartCount?.count > 0 && <span>{shopCartCount?.count}</span>}
        <ShopCartSvg />
      </div>
      <div>
        {comparesCount > 0 && <span>{comparesCount}</span>}
        <CompareSvg active={comparesCount > 0} />
      </div>
      <div
        onClick={() => {
          if (isAuthed) {
            document.location.href =
              "https://vlv.am/profile/dashboard?lang=" + currentLanguage;
          } else {
            dispatch(setShowLoginModal(true));
          }
        }}
      >
        <UserSvg />
      </div>
    </div>
  );
}
