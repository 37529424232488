import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import CloseSvg from "../../../../assets/SVG/CloseSvg";
import { useDispatch, useSelector } from "react-redux";
import MenuArrowSvg from "./assets/MenuArrowSvg";
import BackSvg from "./assets/BackSvg";
import { getMenuData } from "../../../../store/MainSlice";

export default function CatalogMobile({ openCatalog, setOpenCatalog }) {
  const [openCategorys, setOpenCategorys] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const { menuData, headerCategorys, currentLanguage } = useSelector(
    ({ main }) => main
  );
  useEffect(() => {
    if (openCatalog) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openCatalog]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (!menuData.length) {
      dispatch(getMenuData());
    }
  }, [menuData]);
  return (
    <div className={`${styles.container} ${openCatalog && styles.openMenu}`}>
      <>
        <div className={styles.headerSection}>
          <img
            src={require("../../../../assets/logo.png")}
            className={styles.headerLogo}
            alt="VLV"
          />
          <CloseSvg
            className={styles.closeBtn}
            onClick={() => setOpenCatalog(false)}
          />
        </div>
        <div className={styles.menuCategories}>
          <div
            className={styles.categories}
            onClick={() => setOpenCategorys(true)}
          >
            <span>Բաժիններ</span>
            <MenuArrowSvg />
          </div>
          {headerCategorys.map((item, index) => {
            if (item?.icon)
              return (
                <div key={index} className={styles.headerCategorysItem}>
                  <img src={process.env.REACT_APP_STORAGE_URL + item.icon} />
                  <span>{item?.["name_" + currentLanguage]}</span>
                </div>
              );
          })}
        </div>
        <div className={styles.brands}>
          {headerCategorys.map((item, index) => {
            if (item?.logo)
              return (
                <div key={index} className={styles.brandItem}>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + item.logo}
                    alt={"VLV"}
                  />
                </div>
              );
          })}
        </div>
      </>
      <div
        className={`${styles.categoriesSection} ${
          openCategorys && styles.categoriesSectionOpened
        }`}
      >
        <div className={styles.categoriesSectionItem}>
          <div
            className={styles.backBtn}
            onClick={() => setOpenCategorys(false)}
          >
            <BackSvg />
            <span>Բաժիններ</span>
          </div>
          <CloseSvg
            className={styles.closeBtn}
            onClick={() => setOpenCatalog(false)}
          />
        </div>
        {menuData.map((elm, index) => {
          if (elm.from === "category") {
            return (
              <div
                className={styles.categoriesSectionItem}
                onClick={() => setActiveMenu(index)}
                key={index}
              >
                <div>
                  <img
                    src={process.env.REACT_APP_STORAGE_URL + elm.item.icon}
                  />
                  <a>{elm.item["name_" + currentLanguage]}</a>
                </div>
                <MenuArrowSvg />
              </div>
            );
          } else {
            return (
              <div className={styles.categoriesSectionItem} key={index}>
                <div>
                  <img
                    src={
                      process.env.REACT_APP_STORAGE_URL +
                      elm.item.header_category_logo
                    }
                  />
                  <a>{elm.item["title_" + currentLanguage]}</a>
                </div>
                <span />
              </div>
            );
          }
        })}
      </div>

      <div
        className={`${styles.categoriesSection}  ${
          typeof activeMenu === "number" && styles.categoriesSectionOpened
        }`}
      >
        <div className={styles.categoriesSectionItem}>
          <div className={styles.backBtn} onClick={() => setActiveMenu(null)}>
            <BackSvg />
            <img
              style={{ marginLeft: "10px" }}
              src={
                process.env.REACT_APP_STORAGE_URL +
                menuData[activeMenu]?.item?.icon
              }
            />
            <span>{menuData[activeMenu]?.item["name_" + currentLanguage]}</span>
          </div>
          <CloseSvg
            className={styles.closeBtn}
            onClick={() => setOpenCatalog(false)}
          />
        </div>

        {menuData[activeMenu]?.item?.sub_categories?.map((elm, index) => {
          return (
            <div
              className={styles.categoriesSectionItem}
              onClick={() =>
                elm.sub_categories.length && setActiveSubMenu(index)
              }
              key={index}
            >
              <div>
                <img src={process.env.REACT_APP_STORAGE_URL + elm.icon} />
                <a>{elm["name_" + currentLanguage]}</a>
              </div>
              {elm.sub_categories.length ? <MenuArrowSvg /> : null}
            </div>
          );
        })}
      </div>
      <div
        className={`${styles.categoriesSection} ${
          typeof activeSubMenu === "number" && styles.categoriesSectionOpened
        }`}
      >
        <div className={styles.categoriesSectionItem}>
          <div
            className={styles.backBtn}
            onClick={() => setActiveSubMenu(null)}
          >
            <BackSvg />
            <img
              style={{ marginLeft: "10px" }}
              src={
                process.env.REACT_APP_STORAGE_URL +
                menuData[activeMenu]?.item?.sub_categories[activeSubMenu]?.icon
              }
            />
            <span>
              {
                menuData[activeMenu]?.item?.sub_categories[activeSubMenu]?.[
                  "name_" + currentLanguage
                ]
              }
            </span>
          </div>
          <CloseSvg
            className={styles.closeBtn}
            onClick={() => setOpenCatalog(false)}
          />
        </div>

        {menuData[activeMenu]?.item?.sub_categories[
          activeSubMenu
        ]?.sub_categories?.map((elm, index) => {
          return (
            <div className={styles.categoriesSectionItem} key={index}>
              <div>
                {elm.icon && (
                  <img src={process.env.REACT_APP_STORAGE_URL + elm.icon} />
                )}
                <a>{elm["name_" + currentLanguage]}</a>
              </div>
              {elm.sub_categories.length ? <MenuArrowSvg /> : null}
            </div>
          );
        })}
      </div>
    </div>
  );
}
