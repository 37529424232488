import React, { useLayoutEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeartProductAnimation } from "../../store/MainSlice";

export default function AddToFavoriteAnimation() {
  const { shopCartPositions, heartProductAnimation } = useSelector(
    ({ main }) => main
  );
  const imgRef = useRef();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (imgRef.current && Object.keys(shopCartPositions || {}).length) {
      setTimeout(() => {
        imgRef.current.style.top = shopCartPositions?.top - 30 + "px";
        imgRef.current.style.left = shopCartPositions?.left - 120 + "px";
        imgRef.current.style.opacity = 0.5;
        imgRef.current.style.transform = "scale(0.5)";
        setTimeout(() => {
          dispatch(setHeartProductAnimation(null));
        }, 800);
      }, 0);
    }
  }, [imgRef, shopCartPositions, heartProductAnimation]);

  if (!Object.keys(heartProductAnimation || {}).length) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 999999,
        pointerEvents: "none",
      }}
    >
      <div
        ref={imgRef}
        style={{
          position: "absolute",
          top: heartProductAnimation?.position?.top,
          left: heartProductAnimation.position?.left,
          transition: "all 800ms ease-out",
          opacity: 1,
          transfrom: "scale(1)",
          width: "164px",
          height: "auto",
        }}
      >
        <img
          src={process.env.REACT_APP_STORAGE_URL + heartProductAnimation.image}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain",
          }}
        />
      </div>
    </div>
  );
}
