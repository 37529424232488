import React, { useRef, useState } from "react";
import styles from "./style.module.css";
import BackSvg from "../../assets/SVG/BackSvg";
import Login from "./components/Login";
import Signup from "./components/SignUp";
import { useDispatch, useSelector } from "react-redux";
import { setShowLoginModal } from "../../store/MainSlice";
import ForgotPassword from "./components/ForgotPassword";

export default function LoginModal() {
  const showLoginModal = useSelector(({ main }) => main.showLoginModal);
  const [showLogin, setShowLogin] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);

  const dispatch = useDispatch();
  const ContainerRef = useRef();

  if (showLoginModal) {
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      setShowAnimation(true);
    }, 1);
  } else {
    document.body.style.overflow = "auto";
    return;
  }

  return (
    <div
      className={styles.background}
      onClick={(e) => {
        if (!ContainerRef.current.contains(e.target)) {
          dispatch(setShowLoginModal(false));
          setShowAnimation(false);
        }
      }}
    >
      <div
        className={`${styles.container} ${
          showAnimation && styles.openedContainer
        }`}
        ref={ContainerRef}
      >
        <img
          src={require("../../assets/logo.png")}
          alt="VLV"
          className={styles.logo}
        />
        <div className={styles.main}>
          <div className={styles.headContent}>
            <div
              className={styles.backBtn}
              onClick={() => {
                if (!showLogin) {
                  setShowLogin(true);
                } else {
                  if (forgotPassword) {
                    setForgotPassword(false);
                  } else {
                    dispatch(setShowLoginModal(false));
                    setShowAnimation(false);
                  }
                }
              }}
            >
              <BackSvg />
            </div>
            <span>
              {showLogin
                ? forgotPassword
                  ? "Մոռացել ե՞ք գաղտնաբառը"
                  : "Մուտք"
                : "Գրանցվել"}
            </span>
          </div>
          {showLogin ? (
            forgotPassword ? (
              <ForgotPassword />
            ) : (
              <Login
                setShowLogin={setShowLogin}
                setForgotPassword={setForgotPassword}
              />
            )
          ) : (
            <Signup setShowLogin={setShowLogin} />
          )}
          {/* <span>Մուտք գործեք սոցիալական ցանցի միջոցով</span> */}
        </div>
      </div>
    </div>
  );
}
