import React, { memo } from "react";
import styles from "./style.module.css";
import menu_data from "./user_menu_data";

export default memo(function UserMenu() {
  return (
    <div className={styles.container}>
      {menu_data.map((data) => (
        <a href={data.link} className={styles.btn}>
          {data.icon}
          <span>{data.label}</span>
        </a>
      ))}
    </div>
  );
});
