import React, { useState } from "react";
import styles from "./style.module.css";
import PhoneSvg from "../../assets/SVG/PhoneSvg";
import Currency from "./components/Currency";
import CatalogSvg from "../../assets/SVG/CatalogSvg";
import HeaderCategorys from "./components/HeaderCategorys";
import Catalog from "./components/Catalog";
import MobileMenu from "./components/MobileMenu";
import CatalogMobile from "./components/CatalogMobile";

export default function MobileHeader() {
  const [headLineWidth, setHeadLineWidth] = useState(0);
  const [openCatalog, setOpenCatalog] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  document.onscroll = function () {
    var pos = getVerticalScrollPercentage(document.body);
    setHeadLineWidth(Math.round(pos));
  };

  function getVerticalScrollPercentage(elm) {
    var p = elm.parentNode;
    return (
      ((elm.scrollTop || p.scrollTop) / (p.scrollHeight - p.clientHeight)) * 100
    );
  }

  return (
    <>
      {window.innerWidth > 1024 ? (
        <Catalog openCatalog={openCatalog} setOpenCatalog={setOpenCatalog} />
      ) : (
        <CatalogMobile
          openCatalog={openCatalog}
          setOpenCatalog={setOpenCatalog}
        />
      )}
      <MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />

      <header className={styles.container}>
        <div
          className={styles.headLine}
          style={{ width: headLineWidth + "%" }}
        />
        <div className={styles.wrapper}>
          <div className={styles.headerThreeBlocks}>
            <div className={styles.headerTop}>
              <div className={styles.leftContent}>
                <img
                  className={styles.logo}
                  src={require("../../assets/logo.png")}
                  alt="VLV"
                />
              </div>
              <div className={styles.rightContent}>
                <Currency shortBtn />
                <a href="tel:010349944">
                  <PhoneSvg />
                </a>
                <CatalogSvg
                  onClick={() => setOpenMenu(true)}
                  style={{ cursor: "pointer" }}
                  width="28"
                  height="19"
                  fill="#282828"
                />
              </div>
            </div>
            <div className={styles.headerBottom}>
              <div
                className={styles.catalogBtn}
                onClick={() => setOpenCatalog(true)}
              >
                <CatalogSvg />
                <span>Բաժիններ</span>
              </div>
              <div className={styles.searchContainer}>
                <input
                  placeholder="Փնտրիր որ գտնես"
                  className={styles.searchInput}
                />
                <span className={styles.searchBtn}>Որոնել</span>
              </div>
            </div>
            <HeaderCategorys />
          </div>
        </div>
      </header>
    </>
  );
}
