import React from "react";

export default function Paralax2Svg(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="74"
      viewBox="0 0 72 74"
      fill="none"
      {...props}
    >
      <g filter="url(#filter0_ii_1172_41446)">
        <path
          d="M5.38768 52.1576C-0.0982231 50.0525 -1.24806 42.7982 3.31798 39.0998L49.3515 1.81379C53.9176 -1.88457 60.775 0.746817 61.6948 6.55031L70.9687 65.0595C71.8885 70.863 66.181 75.486 60.6951 73.3809L5.38768 52.1576Z"
          fill="#E31335"
        />
      </g>
      <defs>
        <filter
          id="filter0_ii_1172_41446"
          x="-9.5127"
          y="-5.89575"
          width="96.5796"
          height="83.7942"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="16" dy="-6" />
          <feGaussianBlur stdDeviation="9.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1172_41446"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-11" dy="4" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_innerShadow_1172_41446"
            result="effect2_innerShadow_1172_41446"
          />
        </filter>
      </defs>
    </svg>
  );
}
